import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    title: string
}

const ViewField: FC<IProps> = props => {
    const { t } = useTranslation();
    return (
        <div className="mb-4">
            <div><strong>{t(props.title)}</strong></div>
            <div>{props.children}</div>
        </div>
    );
}

export default ViewField;
