import { FC, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageSelection from '../../languageSelection';
import { UserService } from '../../../lmsApi/user/service';
import NavbarNotifications from './navbarNotifications';
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { ClientRoutesPath } from "../../../page/client/routes";
import { DictionaryRoutesPath } from "../../../page/dictionary/routes";
import { AdminRoutesPath } from "../../../page/admin/routes";

const minDistance = 50;

const Header: FC = () => {
    const { t } = useTranslation();
    const [swipe, setSwipe] = useState<{ swiping: boolean, x: number }>({ swiping: false, x: 0 });
    const [showNotifications, setShowNotifications] = useState(false);
    const [showAccountDropdown, setShowAccountDropdown] = useState(false);
    const { user, unviewedNotificationCount } = useTypedSelector(store => store.auth);
    const { menuCollapsed, menuOpened } = useTypedSelector(store => store.layout);
    const { setMenuOpened, toggleMenu } = useActions();

    const onClickMenuLink = () => {
        if (menuOpened) {
            setMenuOpened(!menuOpened);
        }
    }

    const clickShowNotifications = (e: any) => {
        e.preventDefault();
        setShowNotifications(!showNotifications);
    }

    const _onTouchStart = (e: any) => {
        const touch = e.touches[0];
        setSwipe({ ...swipe, x: touch.clientX });
    }

    const _onTouchMove = (e: any) => {
        if (e.changedTouches && e.changedTouches.length) {
            setSwipe({ ...swipe, swiping: true });
        }
    }

    const _onTouchEnd = (e: any) => {
        const touch = e.changedTouches[0];
        const distanse = touch.clientX - swipe.x;;
        const absX = Math.abs(distanse);
        if (swipe.swiping && absX > minDistance && distanse < 1) {
            setMenuOpened(!menuOpened);
        }
        setSwipe({ swiping: false, x: 0 });
    }

    const toggleAccountDropwdown = (e: any) => {
        e.preventDefault();
        setShowAccountDropdown(!showAccountDropdown);
    }

    return (
        <>
            <header className={`main-header ${menuOpened ? 'sidebar-open' : ''} ${menuCollapsed ? 'sidebar-collapse' : ''}`}>
                <Link className="logo font-weight-bold" to="/">
                    <span className="logo-lg">{t('main:brand')}</span>
                    <span className="logo-mini">{t('main:brand-mini')}</span>
                </Link>
                <nav className="navbar navbar-static-top">
                    <a className="sidebar-toggle d-none d-md-block" href="#" role="button" onClick={(e) => { e.preventDefault(); toggleMenu(!menuCollapsed) }}>
                        <i className="fas fa-bars"></i>
                        <span className="sr-only">Toggle navigation</span>
                    </a>
                    <a className="sidebar-toggle d-md-none" href="#" role="button" onClick={(e) => { e.preventDefault(); setMenuOpened(!menuOpened); }}>
                        <i className="fas fa-bars"></i>
                        <span className="sr-only">Toggle navigation</span>
                    </a>
                    <Link className="logo-center font-weight-bold" to="/">
                        <span>{t('main:brand')}</span>
                    </Link>
                    <div className="navbar-custom-menu">
                        {user &&
                            <ul className="nav">
                                <li className="nav-item">
                                    <LanguageSelection />
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link position-relative px-3" href="#" onClick={clickShowNotifications}>
                                        <i className="fas fa-comments fa-lg"></i>
                                        {unviewedNotificationCount > 0 &&
                                            <span className="notification-count">{unviewedNotificationCount}</span>
                                        }
                                    </a>
                                    {showNotifications &&
                                        <NavbarNotifications />
                                    }
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="nav-account" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={toggleAccountDropwdown}>
                                        <img src={UserService.getAvatarPath(user)} className="rounded-circle z-depth-0 avatar" alt="User Image"></img>
                                        <span className="d-none d-md-inline">{UserService.getUserName(user)}</span>
                                    </a>
                                    {showAccountDropdown &&
                                        <div className="dropdown-menu dropdown-menu-right d-block" aria-labelledby="nav-account">
                                            <NavLink className="dropdown-item waves-effect waves-light" to="/profile" >{UserService.getUserName(user)}</NavLink>
                                            <a className="dropdown-item waves-effect waves-light" href="/auth/logout">{t('auth:logout')}</a>
                                        </div>
                                    }
                                </li>
                            </ul>
                        }
                    </div>
                </nav>
            </header>
            <aside className={`main-sidebar ${menuOpened ? ' sidebar-open' : ''} ${menuCollapsed ? ' sidebar-collapse' : ''}`} onTouchStart={_onTouchStart} onTouchMove={_onTouchMove} onTouchEnd={_onTouchEnd}>
                <section>
                    <Link className="logo font-weight-bold" to="/">
                        <span className="logo-lg">{t('main:brand')}</span>
                        <span className="logo-mini">{t('main:brand-mini')}</span>
                    </Link>
                    <ul className="sidebar-menu tree" data-widget="tree">
                        {user &&
                            <>
                                <li>
                                    <NavLink className="d-flex" to={`${ClientRoutesPath.Prefix}/list`} onClick={onClickMenuLink}>
                                        <i className="fas fa-at fa-2x"></i>
                                        <span className="my-auto">{t('client:menu-title')}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="d-flex" to={`${DictionaryRoutesPath.Prefix}/list`} onClick={onClickMenuLink}>
                                        <i className="fas fa-book fa-2x"></i>
                                        <span className="my-auto">{t('dictionary:menu-title')}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="d-flex" to={`${AdminRoutesPath.Prefix}`} onClick={onClickMenuLink}>
                                        <i className="fas fa-cog fa-2x"></i>
                                        <span className="my-auto">{t('admin:menu-title')}</span>
                                    </NavLink>
                                </li>
                            </>
                        }
                    </ul>
                </section>
            </aside>
        </>
    );
}

export default Header;
