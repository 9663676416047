import { FC } from "react";
import { useTypedSelector } from "../hooks/useTypedSelector";

const ContentWrapper: FC = (props) => {
    const { menuOpened, menuCollapsed } = useTypedSelector(store => store.layout);
    return (
        <div className={`content-wrapper ${menuOpened ? 'sidebar-open' : ''} ${menuCollapsed ? 'sidebar-collapse' : ''}`}>
            <div className="container-fluid">{props.children}</div>
        </div>
    );
}

export default ContentWrapper;
