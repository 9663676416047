import React, { FC, useMemo } from "react";
import { Trans } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import TableComponent from "../../../component/table/tableComponent";
import { ErrorHandler } from "../../../service/errorHandler";
import { HeaderItem, TableLoadData, TableRowAction, TableGroupAction } from "../../../component/table/tableComponent/types";
import { UserService } from "../../../lmsApi/user/service";
import { User } from "../../../lmsApi/user/user";
import UserActiveValue from "../../../component/userActiveValue";
import { UserRole } from "../../../enums/userRole";

interface IProps {
    filterValues?: { [key: string]: any }
}

const UserTable: FC<IProps> = (props) => {
    const history = useHistory();
    const header = useMemo<HeaderItem[]>(() => [
        {
            textId: 'main:field-id',
            field: 'id',
            sortable: true,
            getCellObject: function (item: User) {
                return (<td className="align-middle">{item.id}</td>);
            }
        },
        {
            textId: 'main:field-user-name',
            field: 'firstName',
            getCellObject: function (item: User) {
                return (
                    <td className="align-middle">
                        <img src={UserService.getAvatarPath(item)} className="rounded-circle z-depth-0 avatar" />
                        <Link className="link link-hover" to={UserService.getEditLink(item)}>{UserService.getUserName(item)}</Link>
                    </td>
                );
            }
        },
        {
            textId: 'main:field-login',
            field: 'email',
            alwaysVisible: true,
            getCellObject: function (item: User) {
                return (<td className="align-middle">{item.email}</td>);
            }
        },
        {
            textId: "main:field-active",
            field: 'active',
            getCellObject: function (item: User) {
                return (
                    <td className="align-middle">
                        <UserActiveValue user={item} />
                    </td>
                );
            }
        },
        {
            textId: 'main:field-role',
            field: 'roles',
            getCellObject: function (item: User) {
                if (item.roles?.includes(UserRole.admin)) {
                    return (
                        <td className="align-middle">
                            <span className="font-weight-bold"><Trans>main:role-admin</Trans></span>
                        </td>
                    );
                }
                if (item.roles?.includes(UserRole.user)) {
                    return (
                        <td className="align-middle">
                            <span><Trans>main:role-user</Trans></span>
                        </td>
                    );
                }
                return (<td className="align-middle">{item.roles}</td>);
            }
        }
    ], []);

    const rowActions = useMemo<TableRowAction[]>(() => [
        {
            textId: 'main:btn-add-admin-role',
            action: (e: React.SyntheticEvent, item: User) => clickAddAdminRole(e, item)
        },
        {
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, item: User) => clickDelete(e, [item.id])
        }
    ], []);

    const groupActions = useMemo<TableGroupAction[]>(() => [
        {
            id: 'delete',
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, selected: string[]) => clickDelete(e, selected.map(x => Number(x)))
        }
    ], []);

    const clickAddAdminRole = async (e: React.SyntheticEvent, item: User) => {
        try {
            if (e) e.preventDefault();
            await UserService.addAdminRole(item);
        }
        catch (err) {
            ErrorHandler.handle('add adin role', err);
        }
    }

    const clickDelete = async (e: React.SyntheticEvent, ids: number[]) => {
        try {
            if (e) e.preventDefault();
            await UserService.delete(ids);
        }
        catch (err) {
            ErrorHandler.handle('delete users', err);
        }
    }


    const loadData = async (data: TableLoadData) => {
        var requestData = {
            order: { [data.sortBy]: data.sortOrder.toUpperCase() },
            filter: { ...data.filterValues },
            skip: data.skip,
            take: data.take
        };
        const result = await UserService.list(requestData);
        return result;
    }

    return (
        <TableComponent id="user-table-component" filterValues={props.filterValues} defaultVisibleColumns={['id', 'firstName']} header={header}
            loadData={loadData} rowActions={rowActions} groupActions={groupActions} />
    );
}

export default UserTable;
