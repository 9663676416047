import { User } from "../../../lmsApi/user/user";

export interface AuthState {
    user: User | null,
    language?: string,
    isFirstLoadComplete: boolean,
    unviewedNotificationCount: number
}

export interface AuthParams {
    user: User | null,
    language: string,
    unviewedNotificationCount: number
}

export enum AuthActionEnum {
    SET_USER = "SET_USER",
    SET_LANGUAGE = "SET_LANGUAGE",
    SET_AUTH_PARAMS = "SET_AUTH_PARAMS",
}

export interface SetUserAction {
    type: AuthActionEnum.SET_USER,
    payload: User | null
}


export interface SetLanguageAction {
    type: AuthActionEnum.SET_LANGUAGE,
    payload: string
}

export interface SetAuthParamsAction {
    type: AuthActionEnum.SET_AUTH_PARAMS,
    payload: AuthParams
}

export type AuthAction =
    SetUserAction |
    SetLanguageAction |
    SetAuthParamsAction;
