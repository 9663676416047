import React, { FC, useMemo } from "react";
import Select, { SingleValue } from 'react-select';
import { useTranslation } from "react-i18next";
import { MDBInput } from "mdb-react-ui-kit";
import { IFilterFieldProps } from "../../../types/filterFieldProps";
import { RequestDataValue } from "../../../types/requestData";
import { SelectValue } from "../../../../select/SelectValue";
import FilterRangeType from '../../../types/filterRangeType';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';

const FilterFieldDateRange: FC<IFilterFieldProps> = (props) => {
    const { t } = useTranslation();
    const { language } = useTypedSelector(store => store.auth);
    const typeOptions = useMemo(() => {
        const result: SelectValue[] = [];
        FilterRangeType.DateItems().forEach((item) => {
            result.push({
                value: item.id,
                label: t(item.name)
            });
        });
        return result;
    }, []);

    const periodOptions = useMemo(() => {
        const result: SelectValue[] = [{
            label: t('filter:select-period'),
            value: ''
        }];
        FilterRangeType.PeriodItems().forEach((item) => {
            result.push({
                value: item.id,
                label: t(item.name)
            });
        });
        return result;
    }, []);

    const getCurrentValue = () => {
        return props.data ? props.data[0] : {
            value: '',
            label: '',
            type: getType().value
        };
    }

    const getType = () => {
        var type = props.data && props.data.length > 0 ? typeOptions.find(x => props.data && x.value === props.data[0].type) : null;
        if (!type) {
            type = typeOptions[0];
        }
        return type;
    }

    const getValue = () => {
        return props.data && props.data.length > 0 && props.data[0].value.length > 0 ? new Date(props.data[0].value).toISOString().slice(0, 10) : '';
    }

    const getFrom = () => {
        return props.data && props.data.length > 0 && props.data[0].from && props.data[0].from.length > 0 ? new Date(props.data[0].from).toISOString().slice(0, 10) : '';
    }

    const getTo = () => {
        return props.data && props.data.length > 0 && props.data[0].to && props.data[0].to.length > 0 ? new Date(props.data[0].to).toISOString().slice(0, 10) : '';
    }

    const getPeriod = () => {
        var period = props.data && props.data.length > 0 ? periodOptions.find(x => props.data && x.value === props.data[0].value) : null;
        if (!period) {
            period = periodOptions[0];
        }
        return period;
    }

    const onChange = (data: RequestDataValue) => {
        if (data.type === FilterRangeType.Range) {
            data.label = undefined;
            data.value = '';
            if (data.from && data.to) {
                data.label = `${new Date(data.from).toLocaleDateString(language)} - ${new Date(data.to).toLocaleDateString(language)}`;
            }
            else {
                if (data.from) {
                    data.label = `> ${new Date(data.from).toLocaleDateString(language)}`;
                }
                if (data.to) {
                    data.label = `< ${new Date(data.to).toLocaleDateString(language)}`;
                }
            }
        }
        if (data.type === FilterRangeType.Period) {
            data.from = undefined;
            data.to = undefined;
        }
        if (data.type !== FilterRangeType.Range && data.type !== FilterRangeType.Period) {
            data.label = undefined;
            data.from = undefined;
            data.to = undefined;
            if (data.value.length > 0) {
                switch (data.type) {
                    case FilterRangeType.GreaterThan:
                        data.label = `> ${new Date(data.value).toLocaleDateString(language)}`;
                        break;
                    case FilterRangeType.LessThan:
                        data.label = `< ${new Date(data.value).toLocaleDateString(language)}`;
                        break;
                    default:
                        data.label = `${new Date(data.value).toLocaleDateString(language)}`;
                        break;
                }
            }
        }
        props.onChange([data], props.filterItem);
    }

    const onChangeType = (selected: SingleValue<SelectValue>) => {
        const data = getCurrentValue();
        data.value = '';
        data.type = selected?.value;
        onChange(data);
    }

    const onChangePeriod = (selected: SingleValue<SelectValue>) => {
        const data = getCurrentValue();
        data.label = selected && selected.value.length > 0 ? selected?.label : '';
        data.value = selected?.value ?? '';
        onChange(data);
    }

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = getCurrentValue();
        data.value = e.target.value;
        onChange(data);
    }

    const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = getCurrentValue();
        data.from = e.target.value;
        onChange(data);
    }

    const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = getCurrentValue();
        data.to = e.target.value;
        onChange(data);
    }

    const type = getType();

    return (
        <div className="filter-range">
            <label className="active">{t(props.filterItem.Title)}</label>
            <div className="row">
                <div className="col-7 col-xl-4 mb-2 mb-xl-0">
                    <Select value={type} onChange={onChangeType} options={typeOptions} />
                </div>
                {type.value === FilterRangeType.Range &&
                    <>
                        <div className="col-6 col-xl-4">
                            <MDBInput type="date" className="form-control mb-0" max="2100-01-01" onChange={onChangeFrom} value={getFrom()} placeholder={t('filter-field-input-date-placeholder')} />
                        </div>
                        <div className="col-6 col-xl-4">
                            <MDBInput type="date" className="form-control mb-0" max="2100-01-01" onChange={onChangeTo} value={getTo()} placeholder={t('filter-field-input-date-placeholder')} />
                        </div>
                    </>
                }
                {type.value === FilterRangeType.Period &&
                    <div className="col-12 col-xl-8">
                        <div className="form-outline">
                            <Select value={getPeriod()} onChange={onChangePeriod} options={periodOptions} />
                            <label className={`select-label ${getPeriod() ? 'active' : ''}`}>{t('filter:range-type-period')}</label>
                        </div>
                    </div>
                }
                {type.value !== FilterRangeType.Range && type.value !== FilterRangeType.Period &&
                    <div className="col-12 col-xl-8">
                        <MDBInput type="date" max="2100-01-01" onChange={onChangeValue} value={getValue()} placeholder={t('filter:field-input-date-placeholder')} />
                    </div>
                }
            </div>
        </div>
    );
}//<MDBInput type="text" value={props.data && props.data.length > 0 ? props.data[0].value : ''} onChange={onChangeValue} label={t(filterItem.Title)} />

export default FilterFieldDateRange;
