import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import DefaultBlock from '../../../component/layout/defaultBlock';
import InputContainer from '../../../component/layout/inputContainer';
import ContentWrapper from "../../../component/contentWrapper";
import Header from "../../../component/layout/header";
import PageTitle from "../../../component/pageTitle";
import { ErrorHandler } from "../../../service/errorHandler";
import { ToastManager } from "../../../service/toastManager";
import { Restriction } from "../../../lmsApi/restriction/restriction";
import { RestrictionService } from "../../../lmsApi/restriction/service";
import RestrictionCode from '../../../enums/restrictionCode';
import { RestrictionRoutesPath } from "../routes";

const RestrictionEdit: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();
    const [codeValid, setCodeValid] = useState(true);

    const [entity, setEntity] = useState<Restriction>({
        id: id ? (parseInt(id) || 0) : 0,
        code: '',
        name: ''
    });

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        if (entity.id < 1) {
            return;
        }

        try {
            const result = await RestrictionService.get(entity.id);
            if (result) {
                setEntity({
                    ...result,
                });
            }
        }
        catch (err) {
            ErrorHandler.handle('restriction edit refreshData', err);
        }
    }

    const validateCode = () => {
        if (entity.code.length < 1) {
            return false;
        }

        if (!Object.values(RestrictionCode).some(x => entity.code.startsWith(x))) {
            return true;
        }

        const parts = entity.code.split(':');
        if (parts.length !== 2) {
            return false;
        }
        if (parts[1].includes(' ')) {
            return false;
        }
        const count = Number(parts[1]);
        return count > 0;
    }

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!validateCode()) {
            setCodeValid(false);
            return;
        }
        try {
            const result = await RestrictionService.addOrUpdate(entity);
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (entity.id < 1) {
                history.push(`${RestrictionRoutesPath.Prefix}/edit/${result}`);
            }
            setEntity({ ...entity, id: result });
            refreshData();
        }
        catch (err) {
            ErrorHandler.handle('on save restriction', err);
        }
    }

    const setCode = (value: string) => {
        setCodeValid(true);
        setEntity({ ...entity, code: value });
    }

    const onClickSetCode = (code: string) => {
        setCode(`${code}:1`);
    }

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId={entity.id < 1 ? 'restriction:add-title' : 'restriction:edit-title'} />
                <DefaultBlock>
                    <form onSubmit={onSubmit}>
                        <InputContainer>
                            <MDBInput type="text" size="lg" value={entity.name} name='name' onChange={(e: any) => setEntity({ ...entity, name: e.target.value })} required label={t('restriction:name')} />
                        </InputContainer>
                        <InputContainer>
                            <MDBInput className={codeValid ? '' : 'is-invalid'} type="text" size="lg" value={entity.code} name='code' onChange={(e: any) => setCode(e.target.value)} required label={t('restriction:code')}
                                invalid={codeValid ? undefined : true} validation={t('restriction:code-invalid')} />
                        </InputContainer>
                        <InputContainer>
                            <p>{t('restriction:special-code')}</p>
                            {Object.values(RestrictionCode).map(item => (
                                <MDBBtn key={item} type="button" color="link" onClick={() => onClickSetCode(item)}>{item}</MDBBtn>
                            ))}
                        </InputContainer>
                        <div>
                            <MDBBtn type="submit" color="primary">{t('main:btn-save')}</MDBBtn>
                        </div>
                    </form>
                </DefaultBlock>
            </ContentWrapper>
        </>
    )
}

export default RestrictionEdit;
