import FilterItem from '..';
import FilterType from '../../filterType';
import { ISelectProps } from '../additionalParams';

class FilterItemSelect extends FilterItem {
    /**
     * Обычный input для текста
     * @param name поле сущности для фильтрации
     * @param title идентификатор названия
     */
    constructor(name: string, title: string, additionalParams?: ISelectProps) {
        super(FilterType.Select, name, title, additionalParams);
    }
}

export default FilterItemSelect;
