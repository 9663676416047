import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import ApplicationList from '../list';
import ApplicationEdit from '../edit';

export default function ApplicationRoutes() {
    return (
        <Switch>
            <Route path={`${ApplicationRoutesPath.Prefix}/list`} component={ApplicationList} />
            <Route path={`${ApplicationRoutesPath.Prefix}/edit/:id`} component={ApplicationEdit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const ApplicationRoutesPath = {
    Prefix: '/app'
};
