import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { IToastProps, ToastStyle, ToastStyleType } from './types';

// {data && data.date &&
//     <small>11 mins ago//todo</small>
// }

class IToastManager {
    getStyle(style: ToastStyleType) {
        switch (style) {
            case ToastStyleType.error:
                return new ToastStyle({ bgColor: "bg-danger", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-exclamation-circle fa-lg" });
            case ToastStyleType.warn:
                return new ToastStyle({ bgColor: "bg-warning", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-exclamation-triangle fa-lg" });
            case ToastStyleType.info:
                return new ToastStyle({ bgColor: "bg-info", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-info-circle fa-lg" });
            case ToastStyleType.success:
                return new ToastStyle({ bgColor: "bg-success", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-check fa-lg" });
            case ToastStyleType.primary:
                return new ToastStyle({ bgColor: "bg-primary", textColor: "text-white", closeButton: "btn-close-white" });
        }
        return new ToastStyle({ bgColor: "bg-light" });
    }

    error(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.error);
        //this.show(data, ToastStyleType.warn);
        //this.show(data, ToastStyleType.info);
        //this.show(data, ToastStyleType.success);
        //this.show(data, ToastStyleType.primary);
        //this.show(data, ToastStyleType.light);
    }

    warn(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.warn);
    }

    info(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.info);
    }

    success(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.success);
    }

    primary(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.primary);
    }

    light(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.light);
    }

    test(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.error);
        this.show(data, ToastStyleType.warn);
        this.show(data, ToastStyleType.info);
        this.show(data, ToastStyleType.success);
        this.show(data, ToastStyleType.primary);
        this.show(data, ToastStyleType.light);
    }

    private show(data: Partial<IToastProps>, styleType: ToastStyleType) {
        const style = this.getStyle(styleType);
        toast(<div className={style.bgColor}>
            {data && data.title &&
                <div className={`toast-header ${style.textColor} ${style.bgColor}`}>
                    {style.icon &&
                        <i className={`${style.icon} me-2`}></i>
                    }
                    <strong className="me-auto"><Trans>{data.title}</Trans></strong>

                    <button type="button" className="btn-close btn-close-white" aria-label="Close"></button>
                </div>
            }
            {data && data.message &&
                <div className={`toast-body ${style.textColor}`}><Trans>{data.message}</Trans></div>
            }
        </div>, {
            closeButton: false,
            className: 'toast show p-0 mb-2',
            bodyClassName: "p-0",
            progressClassName: 'toast-progress-bar'
        });
    }
}

export const ToastManager = new IToastManager();
