import { MDBInput } from "mdb-react-ui-kit";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IFilterFieldProps } from "../../../types/filterFieldProps";
import { RequestDataValue } from "../../../types/requestData";

const FilterFieldText: FC<IFilterFieldProps> = (props) => {
    const { t } = useTranslation();

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const result: RequestDataValue[] = [];
        if (e.target.value.length > 0) {
            result.push({
                value: e.target.value,
                label: e.target.value
            });
        }
        props.onChange(result, props.filterItem);
    }

    return (
        <div className="form-outline">
            <MDBInput type="text" value={props.data && props.data.length > 0 ? props.data[0].value : ''} onChange={onChangeValue} label={t(props.filterItem.Title)} />
        </div>
    );
}

export default FilterFieldText;
