import { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { MDBInput, MDBCheckbox } from 'mdb-react-ui-kit';
import LanguageSelection from '../../component/languageSelection';
import FormError from "../../component/formError";
import { ErrorHandler } from "../../service/errorHandler";
import { ToastManager } from "../../service/toastManager";
import { UserService } from "../../lmsApi/user/service";
import { AuthRoutesPath } from "./routes";

const RegisterPage: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [errorPolicy, setErrorPolicy] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPolicyChecked, setPolicyChecked] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handlePolicyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPolicyChecked(e.target.checked);
        setErrorPolicy('');
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!isPolicyChecked) {
            setErrorPolicy('auth:error-policy');
            return;
        }
        else {
            setErrorPolicy('');
        }
        try {
            await UserService.register(email, password);
            ToastManager.success({ title: 'main:msg-success', message: 'auth:register-success' });
            history.push(`${AuthRoutesPath.Prefix}/login`);
        }
        catch (err: any) {
            ErrorHandler.handle('register', err);
        }
    }

    // const policy = (
    //     <small>
    //         <Trans i18nKey="auth:policy">
    //             <Link target="_blank" to="/"></Link>
    //             <Link target="_blank" to="/"></Link>
    //         </Trans>
    //     </small>
    // );

    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <form className="text-center register-form app-form" onSubmit={handleSubmit}>
                <p className="h4 mt-4 mb-lg-4">{t('auth:sign-up-title')}</p>
                <section className="row px-lg-5">
                    <div className="col-12 px-lg-5 mb-4 mt-3">
                        <MDBInput type="email" name="new-email" id="new-email" value={email} label={i18next.t('auth:field-email')} onChange={handleEmailChange} required="required" size="lg" autoComplete="new-email" />
                    </div>
                    <div className="col-12 px-lg-5 mb-4">
                        <MDBInput type="password" name="new-password" id="new-password" value={password} label={i18next.t('auth:field-password')} onChange={handlePasswordChange} required="required" size="lg" minLength="10" autoComplete="new-password" />
                    </div>
                    <div className="col-12 px-lg-5 mb-4">
                        <MDBCheckbox name='site-policy' id='site-policy' className="me-0" label={t('auth:policy')} checked={isPolicyChecked} onChange={handlePolicyChange} />
                        <FormError message={errorPolicy} />
                    </div>
                </section>
                <section className="row">
                    <div className="col-12 mb-4">
                        <button className="btn btn-success btn-rounded font-weight-bold px-5" type="submit">{t('auth:sign-up')}</button>
                    </div>
                </section>
                <section className="row px-4 reg-nav">
                    <div className="col-12 col-md-4 mb-2">
                        <Link className="link-hover" to="/login">{t('auth:sign-in-action')}</Link>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Link className="link-hover" to="/restore">{t('auth:forgot-password')}</Link>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                        <LanguageSelection showText />
                    </div>
                </section>
            </form>
        </div>
    );
}

export default RegisterPage;
