import { ToastManager } from "./toastManager";

class CErrorHandler {
    handle(name: string, error: any) {
        error = error?.response ? error.response : error;
        if (error && error.status === 400) {
            if (error.data && error.data.message) {
                console.log(name, 'validation error', error.data.message);
                ToastManager.error({ message: 'main:validation-error-message', title: 'main:validation-error-title' });
                return;
            }
            console.log(name, 'bad request', error.data);
            ToastManager.error({ message: 'main:bad-request-error-message', title: 'main:bad-request-error-title' });
            return;
        }

        if (error && error.status === 401) {
            if (name === 'login') {
                ToastManager.error({ message: 'auth:error-unauthorized', title: 'main:msg-error' });
                return;
            }
            console.log(name, 'access error', error);
            ToastManager.error({ message: 'main:access-error-message', title: 'main:access-error-title' });
            return;
        }

        if (error && error.status === 403) {
            console.log('error.data.error', error.data.error);
            if (error.data.error) {
                ToastManager.error({ message: error.data.error, title: 'main:msg-error' });
                return;
            }
            ToastManager.error({ message: 'main:msg-error', title: 'main:access-error-title' });
            return;
        }

        if (error && (error.status === 404 || error.statusCode === 404)) {
            if (error.data && error.data.status === 404 && error.data.error) {
                ToastManager.error({ message: error.data.error, title: 'main:msg-error' });
                return;
            }
            console.log(name, 'not found error');
            ToastManager.error({ message: 'main:not-found-error-message', title: 'main:not-found-error-title' });
            return;
        }

        if (error && error.status === 500) {
            console.error(name, error);
            ToastManager.error({ message: 'main:unhandled-exception-error-message', title: 'main:unhandled-exception-error-title' });
            return;
        }

        console.error(name, error);
        ToastManager.error({ message: 'main:unhandled-exception-error-message', title: 'main:unhandled-exception-error-title' });
        return;
    }
}

export const ErrorHandler = new CErrorHandler();