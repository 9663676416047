import { LmsApi } from "..";
import { Client } from "./client";

class CClientService {
    async list(requestData: any) {
        const data = await LmsApi.list<Client>('/client/list', requestData);
        return data;
    }

    async get(id: number) {
        const data = await LmsApi.post<Client>('/client/get', { id: id });
        return data;
    }

    async addOrUpdate(entity: Client) {
        if (entity.id < 1) {
            return await LmsApi.post<number>('/client/add', entity);
        }
        return await LmsApi.post<number>('/client/edit', entity);
    }

    async delete(id: number[]) {
        const data = await LmsApi.post('/client/delete', { id: id });
        return data;
    }

    getDetailLink(entity: Client) {
        return `/client/view/${entity.id}`;
    }

    getEditLink(entity: Client) {
        return `/client/edit/${entity.id}`;
    }
}

export const ClientService = new CClientService();
