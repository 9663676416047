import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentWrapper from "../../../component/contentWrapper";
import Header from "../../../component/layout/header";
import PageTitle from "../../../component/pageTitle";
import { useHistory, useParams } from "react-router";
import { Client } from "../../../lmsApi/client/client";
import { ClientService } from "../../../lmsApi/client/service";
import { ClientRoutesPath } from "../routes";
import { LicenceRoutesPath } from "../../licence/routes";
import LicenceTable from "../../licence/list/table";
import Loader from "../../../component/loader";
import Filter from '../../../component/filter';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import FilterItemAsyncSelect from "../../../component/filter/types/filterItem/items/asyncSelect";
import { TariffService } from "../../../lmsApi/tariff/service";
import { ApplicationService } from "../../../lmsApi/application/service";
import FilterItemDateRange from "../../../component/filter/types/filterItem/items/date";

const ClientView: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const { id } = useParams<{ id?: string }>();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const [entity, setEntity] = useState<Client>({
        id: id ? (parseInt(id) || 0) : 0,
        domain: ''
    });

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        if (entity.id < 1) {
            history.push(`${ClientRoutesPath.Prefix}/list`);
            return;
        }

        try {
            const result = await ClientService.get(entity.id);
            if (result) {
                setEntity({
                    ...result,
                });
            }
            if (isLoading) {
                setLoading(false);
            }
        }
        catch (err) {
            history.push(`${ClientRoutesPath.Prefix}/list`);
            // ErrorHandler.handle('clientView refreshData', err);
        }
    }

    const filterItems = [
        new FilterItemHidden('id', { isTyping: 'Y' }),
        new FilterItemAsyncSelect('tariff', 'licence:tariff', { isDefault: "Y", loadOptions: TariffService.selectLoadOptions }),
        new FilterItemAsyncSelect('application', 'licence:application', { isDefault: "Y", loadOptions: ApplicationService.selectLoadOptions }),
        new FilterItemDateRange('lastActivityDate','main:field-lastActivityDate')
    ];

    return (
        <>
            <Header />
            <ContentWrapper>
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <>
                        <PageTitle text={entity.domain} />
                        <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                            <div className="app-filter-actions mb-2 mb-xl-0">
                                <Link className="btn btn-success px-3 waves-effect waves-light m-0 me-2" to={`${LicenceRoutesPath.Prefix}/edit/0?client=${entity.id}`}>{t('main:btn-add')}</Link>
                            </div>
                            <Filter id="filter-client-licence" items={filterItems} onFind={setFilterValues} />
                        </div>
                        <LicenceTable clientId={entity.id} filterValues={filterValues} />
                    </>
                }
            </ContentWrapper>
        </>
    )
}

export default ClientView;
