import { FC, useMemo, useState } from "react";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import Filter from '../../../component/filter';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import ClientTable from './table';
import FilterItemDateRange from "../../../component/filter/types/filterItem/items/date";

const ClientList: FC = () => {
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemHidden('domain', { isTyping: 'Y' }),
        new FilterItemDateRange('lastActivityDate','main:field-lastActivityDate'),
    ], []);

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="client:list-title" />
                <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                    <Filter id="filter-client" items={filterItems} onFind={setFilterValues} />
                </div>
                <ClientTable filterValues={filterValues} />
            </ContentWrapper>
        </>
    );
}

export default ClientList;
