import { FC, useRef, useState } from "react";
import AppAsyncSelect from '../appAsyncSelect';
import { ISelectProps } from "../SelectProps";
import { SelectValue } from "../SelectValue";
import { TariffService } from '../../../lmsApi/tariff/service';
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const TariffSelect: FC<ISelectProps> = (props) => {
    const { language } = useTypedSelector(store => store.auth);
    const [isLoading, setIsLoading] = useState(false);
    const delay = useRef(500);
    const timestamp = useRef(0);

    const loadAction = (inputValue: string, callback: any) => {
        const now = new Date().getTime();
        timestamp.current = now;
        if (!inputValue || inputValue.length < 1) {
            callback(getDefaultOptions());
            return;
        }

        setIsLoading(true);
        setTimeout(async () => {
            if (timestamp.current !== now)
                return;
            try {

                const arItems: SelectValue[] = [];
                const rd = {
                    filter: {
                        "data.name": inputValue
                    }
                };
                const data = await TariffService.list(rd);
                data.items.forEach((item) => {
                    arItems.push({
                        value: `${item.id}` ?? '',
                        label: TariffService.getName(item, language) ?? ''
                    });
                });
                setIsLoading(false);
                callback(arItems);
            }
            catch (err) {
                callback(getDefaultOptions());
            }
        }, delay.current);
    }

    const getDefaultOptions = () => {
        return props.defaultOptions ? props.defaultOptions : [];
    }

    const { options, ...newProps } = props;

    return (
        <AppAsyncSelect {...newProps} options={options} loadOptions={loadAction} loading={isLoading} />
    );
}

export default TariffSelect;
