import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import TableComponent from "../../../component/table/tableComponent";
import { RestrictionService } from "../../../lmsApi/restriction/service";
import { Tariff } from "../../../lmsApi/tariff/tariff";
import { TariffService } from "../../../lmsApi/tariff/service";
import { ErrorHandler } from "../../../service/errorHandler";
import { TableLoadData, HeaderItem, TableRowAction, TableGroupAction } from "../../../component/table/tableComponent/types";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

interface IProps {
    filterValues?: { [key: string]: any }
}

const ApplicationTable: FC<IProps> = (props) => {
    const { language } = useTypedSelector(store => store.auth);

    const header = useMemo<HeaderItem[]>(() => [
        {
            textId: 'main:field-id',
            field: 'id',
            sortable: true,
            getCellObject: (item: Tariff) => {
                return (<td className="align-middle">{item.id}</td>);
            }
        },
        {
            textId: 'main:field-active',
            field: 'active',
            sortable: true,
            getCellObject: (item: Tariff) => {
                return (
                    <td className="align-middle"><Trans>{item.active === 'Y' ? 'main:yes' : 'main:no'}</Trans></td>
                );
            }
        },
        {
            textId: 'tariff:name',
            field: 'name',
            alwaysVisible: true,
            getCellObject: (item: Tariff) => {
                return (
                    <td className="align-middle">
                        <Link className="link-hover" to={TariffService.getDetailLink(item)}>{TariffService.getName(item, language)}</Link>
                    </td>
                );
            }
        },
        {
            textId: 'tariff:restrictions',
            field: 'restrictions',
            getCellObject: (item: Tariff) => {
                return (
                    <td className="align-middle">
                        {item.restrictions.map(x => (
                            <React.Fragment key={x.id}>
                                <Link to={RestrictionService.getDetailLink(x)}>{x.name}</Link><br />
                            </React.Fragment>
                        ))}
                    </td>
                );
            }
        }
    ], []);

    const rowActions = useMemo<TableRowAction[]>(() => [
        {
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, item: Tariff) => clickDelete(e, [item.id])
        }
    ], []);

    const groupActions = useMemo<TableGroupAction[]>(() => [
        {
            id: 'delete',
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, selected: string[]) => clickDelete(e, selected.map(x => Number(x)))
        }
    ], []);

    const loadData = async (data: TableLoadData) => {
        var requestData = {
            order: { [data.sortBy]: data.sortOrder.toUpperCase() },
            filter: { ...data.filterValues },
            skip: data.skip,
            take: data.take
        };
        const result = await TariffService.list(requestData);
        return result;
    }

    const clickDelete = async (e: React.SyntheticEvent, ids: number[]) => {
        try {
            if (e) e.preventDefault();
            await TariffService.delete(ids);
        }
        catch (err) {
            ErrorHandler.handle('delete applications', err);
        }
    }

    return (
        <TableComponent id="tariff-table-component" filterValues={props.filterValues} defaultVisibleColumns={['id', 'name', 'restrictions']} header={header}
            loadData={loadData} rowActions={rowActions} groupActions={groupActions} />
    );
}

export default ApplicationTable;
