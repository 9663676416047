import React, { FC, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import TableComponent from "../../../component/table/tableComponent";
import { Client } from "../../../lmsApi/client/client";
import { ClientService } from '../../../lmsApi/client/service';
import { ErrorHandler } from "../../../service/errorHandler";
import { HeaderItem, TableLoadData, TableRowAction, TableGroupAction } from "../../../component/table/tableComponent/types";

interface IProps {
    filterValues?: { [key: string]: any }
}

const ClientTable: FC<IProps> = (props) => {
    const history = useHistory();
    const header = useMemo<HeaderItem[]>(() => [
        {
            textId: 'main:field-id',
            field: 'id',
            sortable: true,
            getCellObject: (item: Client) => {
                return (<td className="align-middle">{item.id}</td>);
            }
        },
        {
            textId: 'client:domain',
            field: 'domain',
            alwaysVisible: true,
            getCellObject: (item: Client) => {
                return (
                    <td className="align-middle">
                        <Link className="link-hover" to={ClientService.getDetailLink(item)}>{item.domain}</Link>
                    </td>
                );
            }
        },
        {
            textId: 'main:field-lastActivityDate',
            field: 'lastActivityDate',
            alwaysVisible: true,
            sortable: true,
            getCellObject: (item: Client) => {
                return (<td className="align-middle">{item.lastActivityDate ? new Date(item.lastActivityDate).toLocaleDateString() : ''}</td>)

            },
        },
    ], []);

    const rowActions = useMemo<TableRowAction[]>(() => [
        {
            textId: 'main:btn-edit',
            action: (e: React.SyntheticEvent, item: Client) => clickNavigate(ClientService.getEditLink(item)),
            refreshTableAfterAction: false
        },
        {
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, item: Client) => clickDelete(e, [item.id])
        }
    ], []);

    const groupActions = useMemo<TableGroupAction[]>(() => [
        {
            id: 'delete',
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, selected: string[]) => clickDelete(e, selected.map(x => Number(x)))
        }
    ], []);

    const loadData = async (data: TableLoadData) => {
        var requestData = {
            order: { [data.sortBy]: data.sortOrder.toUpperCase() },
            filter: { ...data.filterValues },
            skip: data.skip,
            take: data.take
        };
        const result = await ClientService.list(requestData);
        return result;
    }

    const clickDelete = async (e: React.SyntheticEvent, ids: number[]) => {
        try {
            if (e) e.preventDefault();
            await ClientService.delete(ids);
        }
        catch (err) {
            ErrorHandler.handle('delete clients', err);
        }
    }

    const clickNavigate = async (link: string) => {
        history.push(link);
    }

    return (
        <TableComponent id="client-table-component" filterValues={props.filterValues} defaultVisibleColumns={['id', 'domain']} header={header}
            loadData={loadData} rowActions={rowActions} groupActions={groupActions} />
    );
}

export default ClientTable;
