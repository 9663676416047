
export interface LayoutState {
    pageTitle: string,
    backLink?: string,
    menuOpened: boolean,
    menuCollapsed: boolean
}

export enum LayoutActionEnum {
    SET_TITLE = "SET_TITLE",
    SET_BACKLINK = "SET_BACKLINK",
    SET_MENU_OPENED = "SET_MENU_OPENED",
    SET_MENU_COLLAPSED = "SET_MENU_COLLAPSED"
}

export interface SetTitleAction {
    type: LayoutActionEnum.SET_TITLE,
    payload: string
}

export interface SetBacklinkAction {
    type: LayoutActionEnum.SET_BACKLINK,
    payload?: string
}

export interface SetMenuOpenedAction {
    type: LayoutActionEnum.SET_MENU_OPENED,
    payload: boolean
}

export interface SetMenuCollapsedAction {
    type: LayoutActionEnum.SET_MENU_COLLAPSED,
    payload: boolean
}

export type LayoutAction =
    SetTitleAction |
    SetBacklinkAction |
    SetMenuOpenedAction |
    SetMenuCollapsedAction;
