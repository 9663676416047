import { FC } from "react";

interface IProps {
    className?: string,
    inModal?: boolean
}

const InputContainer: FC<IProps> = props => {
    return (
        <div className="row mb-4">
            <div className={`${props.inModal ? 'col-12' : 'col-md-7 col-lg-8 col-xl-6 col-xxl-4'} ${props.className ? props.className : ''}`}>
                {props.children}
            </div>
        </div>
    );
}

export default InputContainer;
