import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import Filter from '../../../component/filter';
import TariffTable from './table';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import { TariffRoutesPath } from "../routes";

const TariffList: FC = () => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = [
        new FilterItemHidden('data.name', { isTyping: 'Y' })
    ];

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="tariff:list-title" />
                <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                    <div className="app-filter-actions mb-2 mb-xl-0">
                        <Link className="btn btn-success px-3 waves-effect waves-light m-0 me-2" to={`${TariffRoutesPath.Prefix}/edit/0`}>{t('main:btn-add')}</Link>
                    </div>
                    <Filter id="filter-tariff" items={filterItems} onFind={setFilterValues} />
                </div>
                <TariffTable filterValues={filterValues} />
            </ContentWrapper>
        </>
    );
}

export default TariffList;
