import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import ActionEdit from '../edit';
import ActionList from '../list';

export default function ActionRoutes() {
    return (
        <Switch>
            <Route path={`${ActionRoutesPath.Prefix}/edit/:id`} component={ActionEdit} />
            <Route path={`${ActionRoutesPath.Prefix}/list`} component={ActionList} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const ActionRoutesPath = {
    Prefix: '/action'
};
