import { DictionaryEntity } from "../../../models/dictionaryEntity";

export type DictionaryState = {
    dictionary: { [key: string]: DictionaryEntity[] }
    isDictionaryLoading: { [key: string]: boolean }
}

export enum DictionaryActionEnum {
    SET_DICTIONARY = "SET_DICTIONARY",
    SET_LOADING = "SET_LOADING",
}

export interface SetDictionaryAction {
    type: DictionaryActionEnum.SET_DICTIONARY,
    payload: { key: string, items: DictionaryEntity[] }
}

export interface SetLoadingAction {
    type: DictionaryActionEnum.SET_LOADING,
    payload: { key: string, state: boolean }
}

export type DictionaryAction =
    SetDictionaryAction |
    SetLoadingAction;
