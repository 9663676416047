import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import Filter from '../../../component/filter';
import ApplicationTable from './applicationTable';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import FilterItemAsyncSelect from "../../../component/filter/types/filterItem/items/asyncSelect";
import { ApplicationRoutesPath } from "../routes";
import { TariffService } from "../../../lmsApi/tariff/service";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const ApplicationList: FC = () => {
    const { t } = useTranslation();
    const { language } = useTypedSelector(store => store.auth);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemHidden('code', { isTyping: 'Y' }),
        new FilterItemAsyncSelect('defaultTariff', 'application:default-tariff', { isDefault: "Y", loadOptions: TariffService.selectLoadOptions, language: language })
    ], []);

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="application:menu-title" />
                <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                    <div className="app-filter-actions mb-2 mb-xl-0">
                        <Link className="btn btn-success px-3 waves-effect waves-light m-0 me-2" to={`${ApplicationRoutesPath.Prefix}/edit/0`}>{t('main:btn-add')}</Link>
                    </div>
                    <Filter id="filter-application" items={filterItems} onFind={setFilterValues} />
                </div>
                <ApplicationTable filterValues={filterValues} />
            </ContentWrapper>
        </>
    );
}

export default ApplicationList;
