import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import Filter from '../../../component/filter';
import NotificationTable from './table';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import FilterItemDateRange from "../../../component/filter/types/filterItem/items/date";
import FilterItemSelect from "../../../component/filter/types/filterItem/items/select";
import { NotificationRoutesPath } from "../routes";

const NotificationList: FC = () => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemHidden('title', { isTyping: 'Y' }),
        new FilterItemDateRange('createDate', 'main:field-add-date', { isDefault: 'Y' }),
        new FilterItemSelect('criticalState', 'main:field-status', { isDefault: 'Y', options: [{ value: 'normal', label: 'main:critical-state-normal' }, { value: 'critical', label: 'main:critical-state-critical' }] })
    ], []);

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="notification:menu-title" />
                <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                    <div className="app-filter-actions mb-2 mb-xl-0">
                        <Link className="btn btn-success px-3 waves-effect waves-light m-0 me-2" to={`${NotificationRoutesPath.Prefix}/edit/0`}>{t('main:btn-add')}</Link>
                    </div>
                    <Filter id="filter-notification" items={filterItems} onFind={setFilterValues} />
                </div>
                <NotificationTable filterValues={filterValues} />
            </ContentWrapper>
        </>
    );
}

export default NotificationList;
