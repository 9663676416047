import { Route, Switch } from 'react-router-dom';
import ProfilePage from '../../../pages/profile';
import NotFound from '../../home/notFound';

export default function ProfileRoutes() {
    return (
        <Switch>
            <Route path={`${ProfileRoutesPath.Prefix}`} component={ProfilePage} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const ProfileRoutesPath = {
    Prefix: '/profile'
};
