import { LmsApi } from "..";
import { Licence } from "./licence";

class CLicenceService {
    async list(requestData: any) {
        const data = await LmsApi.list<Licence>('/licence/list', requestData);
        return data;
    }

    async get(id: number) {
        const data = await LmsApi.post<Licence>('/licence/get', { id: id });
        return data;
    }

    async addOrUpdate(entity: Licence, client?: number, application?: number, tariff?: number) {
        if (entity.id < 1) {
            return await LmsApi.post<number>('/licence/add', { ...entity, client, application, tariff });
        }
        return await LmsApi.post<number>('/licence/edit', { ...entity, client, application, tariff });
    }

    async delete(id: number[]) {
        const data = await LmsApi.post('/licence/delete', { id: id });
        return data;
    }

    getEditLink(entity: Licence) {
        return `/licence/edit/${entity.id}`;
    }

    getCopyLink(entity: Licence) {
        return `/licence/edit/0?copy=${entity.id}`;
    }
}

export const LicenceService = new CLicenceService();
