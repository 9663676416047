import i18next from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ISelectProps } from "./SelectProps";
import { SelectValue } from "./SelectValue";

interface IState {
    selectActive: boolean
}

class AppAsyncSelect extends React.Component<ISelectProps, IState> {
    constructor(props: ISelectProps) {
        super(props);
        this.state = {
            selectActive: false
        };
    }

    _selectProgramFocus = () => {
        this.setState({ selectActive: true });
    }

    _selectProgramBlur = () => {
        this.setState({ selectActive: false });
    }

    _selectOnChange = (selected: SelectValue, action: any) => {
        if (this.props.onChange) {
            this.props.onChange(selected, action);
        }
    }

    render() {
        const { value, required, title, onChange, isClearable, options, noOptionsMessage, isDisabled, error, ...newProps } = this.props;
        const addClassName = error && error.isFocused ? 'error_focused' : '';
        return (
            <div className="form-outline">
                {options
                    ? <Select {...newProps} isDisabled={isDisabled} value={value} placeholder='' options={options}
                        onChange={this._selectOnChange}
                        onFocus={this._selectProgramFocus} onBlur={this._selectProgramBlur}
                        isClearable={isClearable ?? false}
                        loadingMessage={() => i18next.t('main:select-message-loading')}
                        className={`${this.props.className ? this.props.className : ''} ${addClassName}`}
                    />
                    : <AsyncSelect {...newProps} isDisabled={isDisabled} value={value} cacheOptions placeholder=''
                        onChange={this._selectOnChange}
                        onFocus={this._selectProgramFocus} onBlur={this._selectProgramBlur}
                        isClearable={isClearable ?? false}
                        noOptionsMessage={({ inputValue }) => {
                            //TODO noOptionsMessage in props
                            return i18next.t(`main:select-message-no-options${inputValue && inputValue.length > 0 ? '' : '-empty'}`);
                        }}
                        loadingMessage={() => i18next.t('main:select-message-loading')}
                        className={`${this.props.className ? this.props.className : ''} ${addClassName}`}
                    />
                }
                {title &&
                    <label className={`select-label ${(((Array.isArray(value) && value.length > 0 || (!Array.isArray(value) && value))) || this.state.selectActive) ? 'active' : ''} ${isDisabled ? 'disabled' : ''} ${addClassName ? addClassName : ''}`}><Trans>{title}</Trans></label>
                }
                {required &&
                    <input tabIndex={-1} autoComplete="off" style={{
                        opacity: 0,
                        width: "10%",
                        height: 0,
                        position: "absolute"
                    }} defaultValue={value ? 'Y' : ''} required />
                }
                {error && error.message &&
                    <div className="text-danger"><Trans>{error.message}</Trans></div>
                }
            </div>
        );
    }
}

export default AppAsyncSelect;
