import { createReducer } from '@reduxjs/toolkit';
import { AuthActionEnum, AuthState, SetAuthParamsAction, SetLanguageAction, SetUserAction } from './types';

const initialState: AuthState = {
    user: null,
    isFirstLoadComplete: false,
    unviewedNotificationCount: 0
}

const authReducer = createReducer(initialState, {
    [AuthActionEnum.SET_USER]: (state, action: SetUserAction) => {
        return { ...state, user: action.payload, isLoading: false };
    },
    [AuthActionEnum.SET_LANGUAGE]: (state, action: SetLanguageAction) => {
        return { ...state, language: action.payload };
    },
    [AuthActionEnum.SET_AUTH_PARAMS]: (state, action: SetAuthParamsAction) => {
        return { ...state, user: action.payload.user, language: action.payload.language, unviewedNotificationCount: action.payload.unviewedNotificationCount, isFirstLoadComplete: true }
    }
});

export default authReducer;