import FilterItem from '..';
import FilterType from '../../filterType';
import { ITextProps } from '../additionalParams';

class FilterItemText extends FilterItem {
    /**
     * Обычный input для текста
     * @param name поле сущности для фильтрации
     * @param title идентификатор названия
     */
    constructor(name: string, title: string, additionalParams?: ITextProps) {
        super(FilterType.Text, name, title, additionalParams);
    }
}

export default FilterItemText;
