import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import LicenceEdit from '../edit';

export default function LicenceRoutes() {
    return (
        <Switch>
            <Route path={`${LicenceRoutesPath.Prefix}/edit/:id`} component={LicenceEdit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const LicenceRoutesPath = {
    Prefix: '/licence'
};
