import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import TariffEdit from '../edit';
import TariffList from '../list';

export default function TariffRoutes() {
    return (
        <Switch>
            <Route path={`${TariffRoutesPath.Prefix}/list`} component={TariffList} />
            <Route path={`${TariffRoutesPath.Prefix}/edit/:id`} component={TariffEdit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const TariffRoutesPath = {
    Prefix: '/tariff'
};
