import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import RestrictionEdit from '../edit';
import RestrictionList from '../list';

export default function RestrictionRoutes() {
    return (
        <Switch>
            <Route path={`${RestrictionRoutesPath.Prefix}/list`} component={RestrictionList} />
            <Route path={`${RestrictionRoutesPath.Prefix}/edit/:id`} component={RestrictionEdit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const RestrictionRoutesPath = {
    Prefix: '/restriction'
};
