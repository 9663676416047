import React, { FC, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import TableComponent from "../../../component/table/tableComponent";
import { Licence } from "../../../lmsApi/licence/licence";
import { LicenceService } from '../../../lmsApi/licence/service';
import { ErrorHandler } from "../../../service/errorHandler";
import { HeaderItem, TableLoadData, TableRowAction, TableGroupAction } from "../../../component/table/tableComponent/types";
import { ApplicationService } from "../../../lmsApi/application/service";
import { TariffService } from "../../../lmsApi/tariff/service";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

interface IProps {
    filterValues?: { [key: string]: any },
    clientId: number
}

const LicenceTable: FC<IProps> = (props) => {
    const history = useHistory();
    const { language } = useTypedSelector(store => store.auth);
    const header = useMemo<HeaderItem[]>(() => [
        {
            textId: 'main:field-id',
            field: 'id',
            sortable: true,
            alwaysVisible: true,
            getCellObject: (item: Licence) => {
                return (<td className="align-middle"><Link className="link-hover" to={LicenceService.getEditLink(item)}>{item.id}</Link></td>);
            }
        },
        {
            textId: 'licence:application',
            field: 'application',
            alwaysVisible: true,
            getCellObject: (item: Licence) => {
                return (
                    <td className="align-middle">
                        {item.application &&
                            <Link className="link-hover" to={ApplicationService.getDetailLink(item.application)}>{item.application.code}</Link>
                        }
                    </td>
                )
            }
        },
        {
            textId: 'licence:tariff',
            field: 'tariff',
            alwaysVisible: true,
            getCellObject: (item: Licence) => {
                return (
                    <td className="align-middle">
                        {item.tariff &&
                            <Link className="link-hover" to={TariffService.getDetailLink(item.tariff)}>{TariffService.getName(item.tariff, language)}</Link>
                        }
                    </td>
                )
            }
        },
        {
            textId: 'licence:payment',
            field: 'payment',
            getCellObject: (item: Licence) => {
                return (<td className="align-middle">{item.payment}</td>);
            }
        },
        {
            textId: 'main:field-lastActivityDate',
            field: 'lastActivityDate',
            alwaysVisible: true,
            sortable: true,
            getCellObject: (item: Licence) => {
                return (<td className="align-middle">{item.isLast && item.lastActivityDate ? new Date(item.lastActivityDate).toLocaleDateString() : ''}</td>)

            },
        },
        {
            textId: 'licence:field-activeUsers',
            field: 'activeUsers',
            getCellObject: (item: Licence) => {
                return (<td className="align-middle">{item.isLast ? item.activeUsers : ''}</td>)

            },
        },
        {
            textId: 'main:create-date',
            field: 'createDate',
            getCellObject: (item: Licence) => {
                return (
                    <td className="align-middle">
                        {item.createDate &&
                            <>{new Date(item.createDate).toLocaleString()}</>
                        }
                    </td>
                )
            }
        },
        {
            textId: 'main:update-date',
            field: 'updateDate',
            getCellObject: (item: Licence) => {
                return (
                    <td className="align-middle">
                        {item.updateDate &&
                            <>{new Date(item.updateDate).toLocaleString()}</>
                        }
                    </td>
                )
            }
        }
    ], []);

    const rowActions = useMemo<TableRowAction[]>(() => [
        {
            textId: 'licence:renew',
            action: (e: React.SyntheticEvent, item: Licence) => clickNavigate(e, LicenceService.getCopyLink(item)),
            refreshTableAfterAction: false
        },
        {
            textId: 'main:btn-edit',
            action: (e: React.SyntheticEvent, item: Licence) => clickNavigate(e, LicenceService.getEditLink(item)),
            refreshTableAfterAction: false
        },
        {
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, item: Licence) => clickDelete(e, [item.id])
        }
    ], []);

    const groupActions = useMemo<TableGroupAction[]>(() => [
        {
            id: 'delete',
            textId: 'main:btn-delete',
            action: (e: React.SyntheticEvent, selected: string[]) => clickDelete(e, selected.map(x => Number(x)))
        }
    ], []);

    const loadData = async (data: TableLoadData) => {
        var requestData = {
            order: { [data.sortBy]: data.sortOrder.toUpperCase() },
            filter: { ...data.filterValues, client: [props.clientId] },
            skip: data.skip,
            take: data.take
        };
        const result = await LicenceService.list(requestData);
        return result;
    }

    const clickDelete = async (e: React.SyntheticEvent, ids: number[]) => {
        try {
            if (e) e.preventDefault();
            await LicenceService.delete(ids);
        }
        catch (err) {
            ErrorHandler.handle('delete licence', err);
        }
    }

    const clickNavigate = async (e: React.SyntheticEvent, link: string) => {
        if (e) e.preventDefault();
        history.push(link);
    }

    return (
        <TableComponent id="licence-table-component" filterValues={props.filterValues} defaultVisibleColumns={['id', 'domain']} header={header}
            loadData={loadData} rowActions={rowActions} groupActions={groupActions} />
    );
}

export default LicenceTable;
