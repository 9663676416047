import { FieldType } from '../../enums/fieldType';

export enum ActionType {
    createDeal = 'create-deal',
    changeDealStatus = 'change-deal-status',
    createLead = 'create-lead',
    changeLeadStatus = 'change-lead-status',
    createContact = 'create-contact',
    getNumberOfUsers = 'get-number-of-users',
    updateActivityDate = 'update-activity-date',
    updateActiveUsersCount = 'update-active-users-count',
}

export enum ActionParams {
    webhookLink = 'webhook-link',
    title = 'title',
    dealStage = 'deal-stage',
    dealCategory = 'deal-category',
    leadStatus = 'lead-status',
    userFieldDomain = 'uf-domain',
    userFieldAppCode = 'uf-app-code',
    userFieldInternational = 'uf-international',
    userFieldNumberOfUsers = 'uf-number-of-users',
    userFieldNumberOfUsersList = 'uf-number-of-users-list',
    usersOnlyActive = 'users-only-active',
    userFieldActivityDate = 'uf-activity-date',
    userFieldActiveUsersCount = 'uf-active-users-count',
}

export type ActionParamSettings = {
    name: string,
    type: FieldType,
    isRequired?: boolean,
    keywords?: string[]
}

export enum ActionKeyword {
    domain = "DOMAIN",
    applicationCode = "APP_CODE",
    userEmail = "USER_EMAIL",
    userName = "USER_NAME",
    userLastName = "LAST_NAME",
    userSecondName = "SECOND_NAME",
    userPhone = "PERSONAL_PHONE",
    userMobile = "PERSONAL_MOBILE",
    userActiveCount = "USER_ACTIVE_COUNT"
}
