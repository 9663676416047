import { FC } from "react";
import FilterType from '../../types/filterType';
import FilterFieldDateRange from "./items/dateRange";
import FilterFieldNumberRange from "./items/numberRange";
import FilterFieldSelect from "./items/select";
import FilterFieldAsyncSelect from "./items/asyncSelect";
import FilterFieldText from "./items/text";
import { IFilterFieldProps } from "../../types/filterFieldProps";

const FilterField: FC<IFilterFieldProps> = (props) => {
    return (
        <div>
            {props.filterItem.Type === FilterType.Text &&
                <FilterFieldText {...props} />
            }
            {props.filterItem.Type === FilterType.NumberRange &&
                <FilterFieldNumberRange {...props} />
            }
            {props.filterItem.Type === FilterType.DateRange &&
                <FilterFieldDateRange {...props} />
            }
            {props.filterItem.Type === FilterType.Select &&
                <FilterFieldSelect {...props} />
            }
            {props.filterItem.Type === FilterType.AsyncSelect &&
                <FilterFieldAsyncSelect {...props} />
            }
        </div>
    );
}

export default FilterField;
