import React, { useEffect, useRef, useState, FC } from "react";
import { SingleValue } from 'react-select';
import { useTranslation } from "react-i18next";
import { MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import Loader from '../../loader';
import { ErrorHandler } from "../../../service/errorHandler";
import { HeaderItem, TableLoadData, TableRowAction, TableGroupAction } from './types';
import { SelectValue } from "../../select/SelectValue";
import AppAsyncSelect from "../../select/appAsyncSelect";
import './css/style.css';

interface IProps {
    /**
     * уникальный идентификатор таблицы. Влияет на сохранение\загрузку настроек из localStorage
     */
    id: string,
    /**
     * Идентификатор строки (например id сущности). По умолчанию "id"
     */
    itemId?: string,
    /**
     * Колонки видимые по умолчанию (если нет сохранённых пользователем настроек)
     */
    defaultVisibleColumns: string[],
    header: HeaderItem[],
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
    loadData: (data: TableLoadData) => Promise<{ items: any[], total: number }>,
    /**
     * Обьект со значениями фильтра. Пока значение filterValues не указано, запускаться загрузка данных не будет. Это нужно для ожидания инициализации фильтра
     */
    filterValues?: { [key: string]: any },
    /**
     * Действия над строкой таблицы (элементы выпадающего списка)
     */
    rowActions?: TableRowAction[],
    groupActions?: TableGroupAction[]
}

const Table: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [isFirstLoadComplete, setIsFirstLoadComplete] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showOnPage, setShowOnPage] = useState(true);
    const [onPage, setOnPage] = useState(10);
    const [sortBy, setSortBy] = useState(props.sortBy ?? 'id');
    const [sortOrder, setSortOrder] = useState(props.sortOrder ?? 'desc');
    const [currentPage, setCurrentPage] = useState(1);
    const saveTimestamp = useRef<number>();
    const [items, setItems] = useState<any[]>([]);
    const [total, setTotal] = useState(0);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectedGroupAction, setSelectedGroupAction] = useState('');

    useEffect(() => {
        loadTableSettings();
    }, []);

    useEffect(() => {
        if (!props.filterValues) return;
        tryLoadData();
    }, [props.filterValues, currentPage, sortBy, sortOrder, onPage]);

    //#region Настройки таблицы
    useEffect(() => {
        if (!isFirstLoadComplete) {
            return;
        }
        trySaveTableSettings();
    }, [onPage, visibleColumns, sortBy, sortOrder]);


    const getFromLocalStorage = (key: string) => {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            }
        }
        catch (err) {
            console.warn(err);
            return null;
        }
    };

    const saveToLocalStorage = (key: string, value: any) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        }
        catch (err) {
            console.warn(err)
        }
    }

    const loadTableSettings = () => {
        const settings = getFromLocalStorage(`${props.id}-settings`);
        console.log('load table settings', settings);
        if (settings?.onPage) {
            setOnPage(settings.onPage);
        }
        if (settings?.visibleColumns) {
            setVisibleColumns(settings.visibleColumns);
        }
        else {
            setVisibleColumns(props.defaultVisibleColumns);
        }
        if (settings?.sortBy) {
            setSortBy(settings.sortBy);
        }
        if (settings?.sortOrder) {
            setSortOrder(settings.sortOrder);
        }
    }

    const trySaveTableSettings = () => {
        const timestamp = new Date().getTime();
        saveTimestamp.current = timestamp;
        setTimeout(() => {
            if (timestamp !== saveTimestamp.current) {
                return;
            }
            saveTableSettings();
        }, 400);
    }

    /**
     * Сохраняет onPage, visibleColumns, sortBy, sortOrder
     */
    const saveTableSettings = () => {
        const data = {
            onPage: onPage,
            visibleColumns: visibleColumns,
            sortBy: sortBy,
            sortOrder: sortOrder
        };
        saveToLocalStorage(`${props.id}-settings`, data);
    }

    //#endregion

    const tryLoadData = async () => {
        if (isLoading) { return };
        console.log('try load data', props.filterValues);
        setIsLoading(true);
        try {
            const result = await props.loadData({ sortBy, sortOrder, skip: getOffset(), take: getStep(), filterValues: props.filterValues });
            setSelectedRows([]);
            setItems(result.items);
            setTotal(result.total);
        }
        catch (err) {
            ErrorHandler.handle(`${props.id} loadDataError`, err);
        }
        finally {
            setIsLoading(false);
            if (!isFirstLoadComplete) {
                setIsFirstLoadComplete(true);
            }
        }
    }

    const clickSort = (field: string) => {
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            return;
        }
        setSortBy(field);
        setSortOrder('desc');
    }

    const getEmptyView = () => {
        return (
            <div className="default-block table-empty-block p-2 p-lg-3">
                <i className="fas fa-exclamation-triangle fa-2x me-2 text-danger"></i>
                <span className="ms-2 ms-lg-3">{t('main:data-not-found')}</span>
            </div>
        );
    }

    const getLoadingView = () => {
        return (
            <div className="default-block table-empty-block p-2 p-lg-3">
                <Loader />
            </div>
        );
    }

    const getOffset = () => {
        return ((currentPage - 1) * getStep());
    };

    const getStep = () => {
        return onPage;
    };


    const getArrPagination = () => {
        var lastPage = Math.floor(total / getStep());
        if (total % getStep() > 0)
            lastPage += 1;
        let range = 3;
        var arr: any[] = [];
        for (let i = 1; i <= lastPage; i++) {
            if (i <= range || (i > currentPage - range / 2 && i < currentPage + range / 2) || i > lastPage - range) {
                if (arr[arr.length - 1] && i !== arr[arr.length - 1] + 1)
                    arr.push('...');
                arr.push(i);
            }
        }
        return arr;
    }

    const changeOnPage = (value: number) => {
        if (onPage === value) {
            return;
        }
        setOnPage(value);
        setCurrentPage(1);
    }

    const clickPaging = (value: number | '...') => {
        if (value === '...' || currentPage === value)
            return;
        setCurrentPage(value);
    }

    const clickShowColumn = (e: any, field: string) => {
        e.preventDefault();
        const columns = [...visibleColumns];
        const index = columns.indexOf(field);
        if (index > -1) {
            columns.splice(index, 1);
        }
        else {
            columns.push(field);
        }
        setVisibleColumns(columns);
    }

    const doDropdownAction = async (e: React.SyntheticEvent, actionItem: TableRowAction, item: any) => {
        await actionItem.action(e, item);
        if (actionItem.refreshTableAfterAction ?? true) {
            tryLoadData();
        }
    }

    const isSelectedAll = () => {
        return items.length > 0 && items.length === selectedRows.length;
    }

    const isSelected = (id: string) => {
        return selectedRows.includes(id);
    }

    const selectAll = (e: React.SyntheticEvent) => {
        if (selectedRows.length === items.length) {
            setSelectedRows([]);
            return;
        }
        const selected = [...selectedRows];
        items.forEach((item) => {
            if (!item.id) {
                return;
            }
            const key = String(item.id);
            if (!selected.includes(key)) {
                selected.push(key);
            }
        });
        setSelectedRows(selected);
    }

    const selectRow = (id: string) => {
        const selected = [...selectedRows];
        if (selected.includes(id)) {
            setSelectedRows(selected.filter(x => x !== id));
        }
        else {
            selected.push(id);
            setSelectedRows(selected);
        }
    }

    const getItemKey = (item: any) => {
        if (props.itemId) {
            return item[props.itemId];
        }
        if (item.id) {
            return String(item.id);
        }
        return `${Date.now()}`;
    }

    const changeGroupAction = (selected: SingleValue<SelectValue>) => {
        setSelectedGroupAction(selected ? selected.value : '');
    }

    const getGroupActionOptions = () => {
        const groupActionsOptions: SelectValue[] = [];
        if (!props.groupActions) return groupActionsOptions;
        const actions = props.groupActions;
        if (actions.length > 0 && selectedRows.length > 0) {
            groupActionsOptions.push({
                value: '', label: t('main:table-select-group-action')
            });
            actions.forEach(item => {
                groupActionsOptions.push({
                    value: item.id, label: t(item.textId)
                });
            });
        }
        return groupActionsOptions;
    }

    const clickApplyGroupAction = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedGroupAction.length < 1) {
            return;
        }

        const selectedAction = getSelectedGroupAction();
        if (!selectedAction) {
            return;
        }

        const groupAction = props.groupActions?.find(x => x.id === selectedAction.value);

        if (groupAction) {
            doGroupAction(e, groupAction);
        }
    }

    const getSelectedGroupAction = () => {
        const options = getGroupActionOptions();
        return options.find(x => x.value === selectedGroupAction);
    }

    const getSelectedGroupActionOption = (): SelectValue | null => {
        const action = getSelectedGroupAction();
        if (action) {
            return action;
        }
        return null;
    }

    const doGroupAction = async (e: React.MouseEvent<HTMLButtonElement>, groupAction: TableGroupAction) => {
        await groupAction.action(e, selectedRows);
        if (groupAction.refreshTableAfterAction ?? true) {
            tryLoadData();
        }
    }

    if (!isFirstLoadComplete)
        return getLoadingView();

    if (items.length < 1)
        return getEmptyView();

    const arPagination = getArrPagination();
    const visibleHeaders = props.header.filter(x => x.alwaysVisible || (x.field && visibleColumns.includes(x.field)));
    return (
        <>
            <div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                {!!props.groupActions &&
                                    <th className="align-middle service-th">
                                        <MDBCheckbox id="row-all" checked={isSelectedAll()} onChange={(e: React.SyntheticEvent) => selectAll(e)} />
                                    </th>
                                }
                                {!!props.rowActions &&
                                    <th className="service-th"></th>
                                }
                                {visibleHeaders.map((headerItem, i) => {
                                    if (headerItem.getHeaderObject) {
                                        return (
                                            <React.Fragment key={i}>
                                                {headerItem.getHeaderObject()}
                                            </React.Fragment>
                                        );
                                    }
                                    else {
                                        return (
                                            <th key={i} className={headerItem.sortable ? 'align-middle sortable' : 'align-middle'} onClick={() => headerItem.sortable ? clickSort(headerItem.field) : false}>
                                                {headerItem.icon &&
                                                    <i className={headerItem.icon}></i>
                                                }
                                                {headerItem.textId &&
                                                    <span>{t(headerItem.textId)}</span>
                                                }
                                                {headerItem.sortable && sortBy !== headerItem.field &&
                                                    <i className="fas fa-angle-down ms-1"></i>
                                                }
                                                {sortBy === headerItem.field &&
                                                    <i className={sortOrder !== 'asc' ? 'fas fa-angle-down ms-1' : 'fas fa-angle-up ms-1'}></i>
                                                }
                                            </th>
                                        )
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => {
                                const itemKey = getItemKey(item);
                                return (
                                    <tr key={itemKey}>
                                        {!!props.groupActions &&
                                            <td className="align-middle">
                                                <MDBCheckbox id={`row-${itemKey}`} checked={isSelected(itemKey)} onChange={() => selectRow(itemKey)} />
                                            </td>
                                        }
                                        {!!props.rowActions &&
                                            <td className="align-middle px-0">
                                                <MDBDropdown>
                                                    <MDBDropdownToggle className='shadow-0 px-3' color='white'><i className='fas fa-bars'></i></MDBDropdownToggle>
                                                    <MDBDropdownMenu>
                                                        {props.rowActions.map((actionItem, actionIndex) => (
                                                            <MDBDropdownItem key={actionIndex}>
                                                                <MDBDropdownLink tag="button" onClick={(e: React.SyntheticEvent) => doDropdownAction(e, actionItem, item)}>{t(actionItem.textId)}</MDBDropdownLink>
                                                            </MDBDropdownItem>
                                                        ))}
                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </td>
                                        }
                                        {visibleHeaders.map((headerItem, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {headerItem.getCellObject(item)}
                                                </React.Fragment>
                                            );
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {!!props.groupActions && selectedRows.length > 0 &&
                <div className="table-group-actions">
                    <div className="row">
                        <div className="col-12 col-lg-8 col-xl-5">
                            <div className="d-flex">
                                <AppAsyncSelect value={getSelectedGroupActionOption()} className="w-100" onChange={changeGroupAction} options={getGroupActionOptions()} />
                                {selectedGroupAction && selectedGroupAction.length > 0 &&
                                    <button type="button" className="btn btn-success m-0 ms-2 py-1" onClick={clickApplyGroupAction}>{t('main:btn-apply')}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="row my-3">
                <div className="col-12 col-lg-6">
                    {arPagination.length > 0 &&
                        <div className="table-pagination d-flex">
                            {arPagination.map((item) => (
                                <button key={item} type="button" disabled={item === '...' ? true : false} className={item === currentPage ? 'active' : ''} onClick={() => clickPaging(item)}>{item}</button>
                            ))}
                        </div>
                    }
                </div>
                {showOnPage &&
                    <div className="col-12 col-lg-6 table-on-page d-flex flex-row-reverse">
                        <MDBDropdown>
                            <MDBDropdownToggle className="shadow-0" color='light'><i className="fas fa-cog"></i></MDBDropdownToggle>
                            <MDBDropdownMenu>
                                {props.header.filter(x => !x.alwaysVisible && x.field).map((headerItem, i) => (
                                    <MDBDropdownItem key={i}>
                                        <MDBDropdownLink tag="button" type="button" className={visibleColumns.includes(headerItem.field) ? 'active' : ''} onClick={(e: any) => clickShowColumn(e, headerItem.field)}>
                                            {t(headerItem.textId)}
                                        </MDBDropdownLink>
                                    </MDBDropdownItem>
                                ))}
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        {[100, 50, 25, 10].map(item => (
                            <button className={item === onPage ? 'active' : ''} key={item} onClick={() => changeOnPage(item)}>{item}</button>
                        ))}
                        <span>{t('table:on-page')}</span>
                    </div>
                }
            </div>
        </>
    )
}

export default Table;
