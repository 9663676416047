import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import TableComponent from "../../../component/table/tableComponent";
import { Application } from "../../../lmsApi/application/application";
import { ApplicationService } from '../../../lmsApi/application/service';
import { TariffService } from "../../../lmsApi/tariff/service";
import { ErrorHandler } from "../../../service/errorHandler";
import { TableLoadData, HeaderItem, TableRowAction, TableGroupAction } from "../../../component/table/tableComponent/types";

interface IProps {
    filterValues?: { [key: string]: any }
}

const NotificationTable: FC<IProps> = (props) => {
    return (<>todo</>)
    // const header = useMemo<HeaderItem[]>(() => [
    //     {
    //         textId: 'main:field-id',
    //         field: 'id',
    //         sortable: true,
    //         getCellObject: (item: Application) => {
    //             return (<td className="align-middle">{item.id}</td>);
    //         }
    //     },
    //     {
    //         textId: 'application:code',
    //         field: 'code',
    //         alwaysVisible: true,
    //         getCellObject: (item: Application) => {
    //             return (
    //                 <td className="align-middle">
    //                     <Link className="link-hover" to={ApplicationService.getDetailLink(item)}>{item.code}</Link>
    //                 </td>
    //             );
    //         }
    //     },
    //     {
    //         textId: 'application:default-tariff',
    //         field: 'defaultTariff',
    //         getCellObject: (item: Application) => {
    //             return (
    //                 <td className="align-middle">
    //                     {item.defaultTariff &&
    //                         <Link className="link-hover" to={TariffService.getDetailLink(item.defaultTariff)}>{item.defaultTariff.name}</Link>
    //                     }
    //                 </td>
    //             );
    //         }
    //     }
    // ], []);

    // const rowActions = useMemo<TableRowAction[]>(() => [
    //     {
    //         textId: 'main:btn-delete',
    //         action: (e: React.SyntheticEvent, item: Application) => clickDelete(e, [item.id])
    //     }
    // ], []);

    // const groupActions = useMemo<TableGroupAction[]>(() => [
    //     {
    //         id: 'delete',
    //         textId: 'main:btn-delete',
    //         action: (e: React.SyntheticEvent, selected: string[]) => clickDelete(e, selected.map(x => Number(x)))
    //     }
    // ], []);

    // const loadData = async (data: TableLoadData) => {
    //     var requestData = {
    //         order: { [data.sortBy]: data.sortOrder.toUpperCase() },
    //         filter: { ...data.filterValues },
    //         skip: data.skip,
    //         take: data.take
    //     };
    //     const result = await ApplicationService.list(requestData);
    //     return result;
    // }

    // const clickDelete = async (e: React.SyntheticEvent, ids: number[]) => {
    //     try {
    //         if (e) e.preventDefault();
    //         await ApplicationService.delete(ids);
    //     }
    //     catch (err) {
    //         ErrorHandler.handle('delete applications', err);
    //     }
    // }

    // return (
    //     <TableComponent id="application-table-component" filterValues={props.filterValues} defaultVisibleColumns={['id', 'name']} header={header}
    //         loadData={loadData} rowActions={rowActions} groupActions={groupActions} />
    // );
}

export default NotificationTable;
