import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Notification from "../lmsApi/notification/notification";
import { NotificationService } from '../lmsApi/notification/service';

interface IProps {
    item: Notification
}

const NotificationText: FC<IProps> = ({ item }) => {
    const [data] = useState(NotificationService.getData(item));
    const { t } = useTranslation();
    return (
        <>
            <span>{t(data.text)}</span>
            {data.link &&
                <NavLink className="notification-link link-hover active ms-1" to={data.link.relative ? data.link.relative : data.link.href}>{data.link.text}</NavLink>
            }
        </>
    );
}

export default NotificationText;
