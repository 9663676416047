import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import Filter from '../../../component/filter';
import LogTable from './logTable';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import FilterItemText from "../../../component/filter/types/filterItem/items/text";
import FilterItemDate from "../../../component/filter/types/filterItem/items/date";
import FilterItemSelect from "../../../component/filter/types/filterItem/items/select";
import FilterItemAsyncSelect from "../../../component/filter/types/filterItem/items/asyncSelect";
import { AppObject } from '../../../enums/appObject';
import { LogType } from '../../../enums/logType';
import { UserService } from "../../../lmsApi/user/service";

const LogList: FC = () => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const objectTypes: { value: string, label: string }[] = [];
    Object.values(AppObject).forEach(x => {
        objectTypes.push({
            value: x,
            label: t(`main:object-type-${x}`)
        });
    });

    const logTypes: { value: string, label: string }[] = [];
    Object.values(LogType).forEach(x => {
        logTypes.push({
            value: x,
            label: t(`main:log-type-${x}`)
        });
    });

    const filterItems = [
        new FilterItemHidden('id', { isTyping: 'Y' }),
        new FilterItemDate('createDate', 'main:field-date-and-time', { isDefault: "Y" }),
        new FilterItemText('ip', 'main:field-ip'),
        new FilterItemSelect('type', 'main:field-type', { isDefault: "Y", options: logTypes }),
        new FilterItemSelect('objectType', 'main:field-object-type', { isDefault: "Y", options: objectTypes }),
        new FilterItemAsyncSelect('userId', 'main:field-user', { isDefault: "Y", loadOptions: UserService.selectLoadOptions })

        // new FilterItem(FilterType.UserSelect, 'userId', 'field-user', { Default: 'Y' })
    ];
    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="admin:log-title" />
                <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                    <Filter id="filter-logs" items={filterItems} onFind={setFilterValues} />
                </div>
                <LogTable filterValues={filterValues} />
            </ContentWrapper>
        </>
    );
}

export default LogList;
