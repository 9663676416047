import { createReducer } from '@reduxjs/toolkit';
import { DictionaryActionEnum, DictionaryState, SetDictionaryAction, SetLoadingAction } from './types';

const initialState: DictionaryState = {
    isDictionaryLoading: {},
    dictionary: {}
}

const dictionaryReducer = createReducer(initialState, {
    [DictionaryActionEnum.SET_DICTIONARY]: (state, action: SetDictionaryAction) => {
        return {
            ...state,
            dictionary: { ...state.dictionary, [action.payload.key]: action.payload.items },
            isDictionaryLoading: { ...state.isDictionaryLoading, [action.payload.key]: false }
        };
    },
    [DictionaryActionEnum.SET_LOADING]: (state, action: SetLoadingAction) => {
        return {
            ...state,
            isDictionaryLoading: { ...state.isDictionaryLoading, [action.payload.key]: action.payload.state }
        };
    },
});

export default dictionaryReducer;
