import { FC, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import Notification from '../../../lmsApi/notification/notification';
import { ErrorHandler } from '../../../service/errorHandler';
import Loader from '../../loader';
import NotificationText from '../../notificationText';
import { NotificationRoutesPath } from '../../../page/notification/routes';

const NavbarNotifications: FC = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<Notification[]>([]);

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        const _ = this;
        try {
            const request = await axios.post('/notification/unviewed');
            const result: { items: Notification[] } = request.data;
            setItems(result.items);
        }
        catch (err) {
            ErrorHandler.handle('load NavbarNotifications', err);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="navbar-notifications" aria-labelledby="nav-notifications">
            {isLoading &&
                <Loader />
            }
            {!isLoading && items.length < 1 &&
                <div className="notification-item p-3">
                    <div className="text">{t('notification-empty')}</div>
                </div>
            }
            {!isLoading && items.length > 0 && items.map((item, index) => (
                <div className="notification-item p-3" key={index}>
                    <div className="d-flex mb-2 date">
                        <div className={`critical-state ${item.criticalState}`}></div>
                        {moment(item.createDate).format('L LTS')}
                    </div>
                    <div className="text">
                        <NotificationText item={item} />
                    </div>
                </div>
            ))
            }
            <div className="notification-item notification-more">
                <NavLink className="link-hover d-flex justify-content-center active" to={`${NotificationRoutesPath.Prefix}/list`}>
                    <span>...</span>
                </NavLink>
            </div>
        </div>
    );
}

export default NavbarNotifications;
