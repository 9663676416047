import { LmsApi } from "..";
import { Restriction } from "./restriction";

class CRestrictionService {
    async list(requestData: any) {
        if (LmsApi.isTest) {
            const items: Restriction[] = [
                { id: 1, code: 'code_1', name: 'name 1' },
                { id: 2, code: 'code_2', name: 'name 1' },
                { id: 3, code: 'code_3', name: 'name 1' },
                { id: 4, code: 'code_4', name: 'name 1' },
                { id: 5, code: 'code_5', name: 'name 1' },
            ]
            return {
                items: items,
                total: 100
            };
        }

        const data = await LmsApi.list<Restriction>('/restriction/list', requestData);
        return data;
    }

    async get(id: number) {
        const data = await LmsApi.post<Restriction>('/restriction/get', { id: id });
        return data;
    }

    async addOrUpdate(entity: Restriction) {
        if (entity.id < 1) {
            return await LmsApi.post<number>('/restriction/add', entity);
        }
        return await LmsApi.post<number>('/restriction/edit', entity);
    }

    async delete(id: number[]) {
        const data = await LmsApi.post('/restriction/delete', { id: id });
        return data;
    }

    getDetailLink(entity: Restriction) {
        return `/restriction/edit/${entity.id}`;
    }
}

export const RestrictionService = new CRestrictionService();
