
import { FC } from "react";
import { useTranslation } from 'react-i18next';

interface IProps {
    titleId?: string,
    text?: string
}

const PageTitle: FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <h1 className="default-block page-title mt-2">{props.titleId ? t(props.titleId) : props.text}</h1>
    );
}

export default PageTitle;
