import { FC, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { MultiValue } from 'react-select';
import { debounce } from "lodash";
import { IFilterFieldProps } from "../../../types/filterFieldProps";
import { RequestDataValue } from "../../../types/requestData";
import { IAsyncSelectProps } from "../../../types/filterItem/additionalParams";

const FilterFieldSelect: FC<IFilterFieldProps> = (props) => {
    const { t } = useTranslation();
    const loadDelay = useRef(800);
    const [focus, setFocus] = useState(false);
    var value = props.data ? props.data.map(x => {
        return { value: x.value, label: x.label ?? '' };
    }) : null;


    const handleOnLoadOptions = (inputValue: string, callback: (data: { value: string, label: string }[]) => void) => {
        const additionalParams = props.filterItem.additionalParams as IAsyncSelectProps;
        additionalParams.loadOptions(inputValue, additionalParams).then((result: { value: string, label: string }[]) => callback(result));
    }

    const onLoadOptions = useCallback(debounce(handleOnLoadOptions, loadDelay.current), []);

    const onChangeValue = (selected: MultiValue<{ value: string, label: string }>) => {
        const result: RequestDataValue[] = [];
        if (selected) {
            selected.forEach(element => {
                result.push(element);
            });
        }
        props.onChange(result, props.filterItem);
    }

    const onFocus = () => {
        if (focus) { return; }
        setFocus(true);
    }

    const obBlur = () => {
        if (!focus) { return; }
        setFocus(false);
    }

    return (
        <div className="form-outline">
            <AsyncSelect value={value} onChange={onChangeValue} loadOptions={onLoadOptions} isClearable isMulti placeholder=""
                noOptionsMessage={({ inputValue }) => t(`filter:select-message-no-options${inputValue && inputValue.length > 0 ? '' : '-empty'}`)}
                loadingMessage={({ inputValue }) => t('filter:select-message-loading')}
                onFocus={onFocus} onBlur={obBlur} />
            <label className={`select-label ${focus || value && value.length > 0 ? 'active' : ''}`}>{t(props.filterItem.Title)}</label>
        </div>
    );
}

export default FilterFieldSelect;
