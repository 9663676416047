import FilterItem from '..';
import FilterType from '../../filterType';
import { IHiddenProps } from '../additionalParams';

class FilterItemHidden extends FilterItem {
    /**
     * Скрытый элемент фильтра
     * @param name поле сущности для фильтрации
     * @param additionalParams дополнительные параметры
     */
    constructor(name: string, additionalParams?: IHiddenProps) {
        super(FilterType.Hidden, name, '', additionalParams);
    }
}

export default FilterItemHidden;
