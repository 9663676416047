import React, { FC, useEffect, useState } from "react";
import DefaultBlock from '../../../component/layout/defaultBlock';
import InputContainer from '../../../component/layout/inputContainer';
import ContentWrapper from "../../../component/contentWrapper";
import Header from "../../../component/layout/header";
import PageTitle from "../../../component/pageTitle";
import { useHistory, useParams } from "react-router";
import { Application } from "../../../lmsApi/application/application";
import { ApplicationService } from "../../../lmsApi/application/service";
import { ErrorHandler } from "../../../service/errorHandler";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { ToastManager } from "../../../service/toastManager";
import { SelectValue } from "../../../component/select/SelectValue";
import TariffSelect from '../../../component/select/items/tariff';
import AppActionSelect from '../../../component/select/items/appAction';
import { Action } from "../../../lmsApi/action/action";
import { TariffService } from "../../../lmsApi/tariff/service";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const ApplicationEdit: FC = () => {
    const { t } = useTranslation();
    const { language } = useTypedSelector(store => store.auth);
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();
    const [tariff, setTariff] = useState<SelectValue | null>(null);
    const [installActions, setInstallActions] = useState<SelectValue[] | null>(null);
    const [uninstallActions, setUninstallActions] = useState<SelectValue[] | null>(null);

    const [entity, setEntity] = useState<Application>({
        id: id ? (parseInt(id) || 0) : 0,
        code: ''
    });

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        if (entity.id < 1) {
            return;
        }

        try {
            const result = await ApplicationService.get(entity.id);
            if (result) {
                setEntity({
                    ...result,
                });
                setTariff(result.defaultTariff ? { label: TariffService.getName(result.defaultTariff, language), value: `${result.defaultTariff.id}` } : null);
                setInstallActions(result.installActions ? result.installActions.map((x: Action) => ({ label: x.name, value: `${x.id}` })) : null);
                setUninstallActions(result.uninstallActions ? result.uninstallActions.map((x: Action) => ({ label: x.name, value: `${x.id}` })) : null);
            }
        }
        catch (err) {
            ErrorHandler.handle('ApplicationEdit refreshData', err);
        }
    }

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {

            const result = await ApplicationService.addOrUpdate(entity,
                installActions ? installActions.map(x => Number(x.value)) : [],
                uninstallActions ? uninstallActions.map(x => Number(x.value)) : [],
                tariff ? Number(tariff.value) : undefined
            );
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (entity.id < 1) {
                history.push(`/app/edit/${result}`);
            }
            setEntity({ ...entity, id: result });
            refreshData();
        }
        catch (err) {
            ErrorHandler.handle('on save app', err);
        }
    }

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId={entity.id < 1 ? 'application:add-title' : 'application:edit-title'} />
                <DefaultBlock>
                    <form onSubmit={onSubmit}>
                        <InputContainer>
                            <MDBInput type="text" size="lg" value={entity.code} name='code' onChange={(e: any) => setEntity({ ...entity, code: e.target.value })} required label={t('application:code')} />
                        </InputContainer>
                        <InputContainer>
                            <TariffSelect className="select-lg" value={tariff} onChange={setTariff} isClearable required title='application:default-tariff' />
                        </InputContainer>
                        <InputContainer>
                            <AppActionSelect className="select-lg" value={installActions} onChange={setInstallActions} isMulti isClearable title='application:install-actions' />
                        </InputContainer>
                        <InputContainer>
                            <AppActionSelect className="select-lg" value={uninstallActions} onChange={setUninstallActions} isMulti isClearable title='application:uninstall-actions' />
                        </InputContainer>
                        <div>
                            <MDBBtn type="submit" color="primary">{t('main:btn-save')}</MDBBtn>
                        </div>
                    </form>
                </DefaultBlock>
            </ContentWrapper>
        </>
    )
}

export default ApplicationEdit;
