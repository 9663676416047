import React from "react";
import axios from 'axios';
import Dropzone from 'react-dropzone'
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { MDBInput } from 'mdb-react-ui-kit';
import PageTitle from '../component/pageTitle';
import Header from '../component/layout/header';
import ContentWrapper from '../component/contentWrapper';
import Loader from '../component/loader';
import FormError from '../component/formError';
import UserActiveValue from "../component/userActiveValue";
import { UserContext } from '../context/user-context';
import Utils from '../utils';
import User from '../lmsApi/user/user';

import '../css/profile.css';
import { UserRole } from "../enums/userRole";
import { UserService } from "../lmsApi/user/service";

class ProfilePage extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super();
        const _ = this;
        this.state = {
            firstLoadComplete: false,
            user: false,
            currentPage: ''
        };
        this.pages = [
            { id: 'data', name: 'profile:user-credentials', icon: 'fas fa-user-alt me-2', access: () => { return true; } },
            { id: 'notifications', name: 'profile:notifications', icon: 'fas fa-envelope me-2', access: () => { return _.accessNofifications(); } }
        ];
    }

    componentDidMount() {
        this.refreshData();
    }

    isCurrentUser() {
        return this.context.user.id === this.state.user.id;
    }

    accessNofifications() {
        return this.isCurrentUser() || this.context.isAdmin();
    }

    refreshData() {
        const _ = this;
        const userId = parseInt(_.props.match.params.id) || 0;
        axios.post('/user/profile', userId > 0 ? { id: userId } : {}).then(function (response) {
            const result = response.data;
            if (result.user) {
                _.setState({
                    firstLoadComplete: true,
                    currentPage: 'data',
                    user: result.user
                });
            }
            else {
                _.props.history.push('/');
            }
        });
    }

    addAdminRole = () => {
        axios.post('/user/addAdminRole', { id: this.state.user.id }).then(function (response) {
            const result = response.data;
            console.log('result addAdminRole', result);
        });
    }

    addModeratorRole = () => {
        axios.post('/user/addModeratorRole', { id: this.state.user.id }).then(function (response) {
            const result = response.data;
            console.log('result addModeratorRole', result);
        });
    }

    changeUserData = (user: User) => {
        this.setState({ user: user });
        // const _ = this;
        // if (_.state.user.id === _.context.user.id) {
        //     _.context.refreshUser();
        // }
    }

    clickChangePage = (page) => {
        this.setState({
            currentPage: page
        });
    }

    uploadAvatar = (acceptedFiles) => {
        if (acceptedFiles.length < 1)
            return;

        var formData = new FormData();
        formData.append(`file`, acceptedFiles[0]);
        formData.append('userId', this.state.user.id);

        const _ = this;
        fetch('/user/updateAvatar', {
            method: 'POST',
            body: formData,
        }).then(response => {
            return response.json();
        }).then(result => {
            if (result.error) {
                console.log(result.error_description);
            }
            else {
                _.refreshData();
                if (_.state.user.id === _.context.user.id) {
                    _.context.refreshUser();
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    onClickDelete = (e) => {
        if (e) e.preventDefault();
        const _ = this;
        axios.post('/user/delete', { id: [_.state.user.id] }).then(function (response) {
            //const result = response.data;
            _.props.history.push('/users');
        }).catch(error => {
            const err = error.response.data;
            console.error(err);
        });
    }

    onClickAuthAsUser = (e) => {
        if (e) e.preventDefault();
        const _ = this;
        axios.post('/auth/loginAsUser', { id: _.state.user.id }).then(function (response) {
            //const result = response.data;
            _.context.refreshUser();
            _.props.history.push('/profile')
        }).catch(error => {
            const err = error.response.data;
            console.error(err);
        });
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <ContentWrapper>
                    <div className="container-fluid profile">
                        <PageTitle titleId="profile:settings-title" />
                        <div className="mt-3 row">
                            {!this.state.firstLoadComplete &&
                                <div className="col-12">
                                    <div className="default-block p-3">
                                        <Loader />
                                    </div>
                                </div>
                            }
                            {this.state.firstLoadComplete &&
                                <React.Fragment>
                                    <div className="col-12 col-xl-4">
                                        <div className="default-block p-3">
                                            {this.isCurrentUser() &&
                                                <div className="d-flex flex-row-reverse mb-n3">
                                                    <a className="link" href="/auth/logout">
                                                        <i className="fas fa-sign-out-alt"></i>
                                                    </a>
                                                </div>
                                            }
                                            <Dropzone onDrop={this.uploadAvatar} noClick noKeyboard accept="image/png, image/gif, image/jpeg">
                                                {({ getRootProps, getInputProps, isDragActive, open }) => (
                                                    <div className="d-flex justify-content-center" {...getRootProps()}>
                                                        <div className="avatar-block">
                                                            <input {...getInputProps()} />
                                                            <img src={UserService.getAvatarPath(this.state.user)} alt="avatar" />
                                                            <button className={`btn-upload-image mt-2 ${isDragActive ? 'active' : ''}`} onClick={open}>
                                                                {!isDragActive
                                                                    ? <i className="fas fa-camera"></i>
                                                                    : <i className="fas fa-file-upload"></i>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="d-flex justify-content-center user-name mb-3 mt-1">
                                                {this.state.user.Name}
                                            </div>
                                            <div className="">
                                                {this.pages.map((item) => {
                                                    if (item.access()) {
                                                        return (
                                                            <div className={this.state.currentPage === item.id ? 'd-flex justify-content-center active' : 'd-flex justify-content-center'} key={item.id}>
                                                                <button className="btn btn-profile-page mb-2" onClick={() => this.clickChangePage(item.id)}>
                                                                    <i className={item.icon}></i>
                                                                    <span><Trans>{item.name}</Trans></span>
                                                                </button>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-8 mt-3 mt-xl-0">
                                        {!this.state.firstLoadComplete &&
                                            <div className="default-block p-3">
                                                <Loader />
                                            </div>
                                        }
                                        {this.state.currentPage === 'data' &&
                                            <ProfilePageData user={this.state.user} changeUserData={this.changeUserData} onClickDelete={this.onClickDelete} onClickAuthAsUser={this.onClickAuthAsUser} />
                                        }
                                        {this.state.currentPage === 'notifications' &&
                                            <ProfilePageNotifications admin={this.context.isAdmin()} />
                                        }
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </ContentWrapper>
            </React.Fragment>
        );
    }
}
//<button className="btn btn-primary" onClick={this.addModeratorRole}>moderator</button>

class ProfilePageData extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super();
        this.state = {
            firstName: props.user.Name,
            loading: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.loading)
            return;

        const _ = this;
        _.setState({ loading: true });

        axios.post('/user/edit', {
            id: this.props.user.id,
            firstName: this.state.firstName
        }).then((response) => {
            const result = response.data;
            console.log('result', result);
            if (result.user) {
                _.props.changeUserData(result.user);
            }
        }).catch((error) => {
            const err = error.response.data;
            const res = Utils.checkRequestError(err);
            if (res) {
                console.log('err', res);
                return;
            }
            console.log('err', err.responseJSON);
        }).then(() => {
            _.setState({ loading: false });
        })
    }

    handleFirstNameChange = (e) => {
        this.setState({ firstName: e.target.value });
    }

    render() {
        return (
            <div className="default-block p-3">
                <div className="profile-user-page-title">
                    <span><Trans>profile:user-credentials</Trans></span>
                </div>
                <div className="row">
                    <div className="col-12 col-xl-8">
                        <form onSubmit={this.handleSubmit}>
                            <div className="data-block my-4">
                                <div className="title"><Trans>profile:block-personal-data</Trans></div>
                                <div>
                                    <span className="me-2"><Trans>profile:active</Trans></span><UserActiveValue user={this.props.user} />
                                </div>
                                <div className="mt-2">
                                    <span className="me-2"><Trans>profile:login</Trans></span><span>{this.props.user.email}</span>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-6 col-xl-5 mb-3">
                                        <label className="row-label" htmlFor="first-name"><Trans>profile:first-name</Trans></label>
                                        <MDBInput type="text" name="first-name" id="first-name" value={this.state.firstName} onChange={this.handleFirstNameChange} size="lg" autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success btn-rounded mt-2"><Trans>profile:save-data</Trans></button>
                            {this.context.isAdmin() && this.context.user.id !== this.props.user.id &&
                                <React.Fragment>
                                    <button className="btn btn-danger btn-rounded mt-2" onClick={this.props.onClickDelete}><Trans>btn-delete</Trans></button>
                                    {!this.props.user.roles.includes(UserRole.admin) &&
                                        <button className="btn btn-warning btn-rounded mt-2" onClick={this.props.onClickAuthAsUser}><Trans>auth-login-as-user</Trans></button>
                                    }
                                </React.Fragment>
                            }
                        </form>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="row">
                            {this.context.user.id === this.props.user.id &&
                                <div className="col-12 col-md-6 col-xl-12 mt-3 mb-3 mt-xl-0 data-block">
                                    <div className="p-3 password-block">
                                        <ProfilePagePassword />
                                    </div>
                                </div>
                            }
                            <div className="col-12 col-md-6 col-xl-12 mt-3 mt-xl-0">
                                <div className="p-3 info-block">
                                    <p className="mb-2">
                                        <span><Trans>profile:info</Trans></span>
                                    </p>
                                    <p className="m-0">
                                        <a href={i18next.t('profile:phone-href')}><Trans>profile:phone</Trans></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ProfilePagePassword extends React.Component {
    constructor(props) {
        super();
        this.state = {
            old: '',
            new: '',
            loading: false,
            success: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.loading)
            return;
        this.setState({ loading: true, error: '' });
        const _ = this;
        axios.post('/auth/changePassword', { oldPassword: _.state.old, newPassword: _.state.new }).then((response) => {
            const result = response.data;
            if (result.error) {
                _.setState({ error: result.error_description });
            }
            else {
                _.setState({
                    success: true,
                    old: '',
                    new: '',
                    error: ''
                });
            }
        }).catch((error) => {
            const err = error.response.data;
            const res = Utils.checkRequestError(err);
            if (res) {
                _.setState({ error: res });
                return;
            }
            console.log('err', err);
        }).then(() => {
            _.setState({ loading: false });
        });
    }

    handleOldChange = (e) => {
        this.setState({ old: e.target.value });
    }

    handleNewChange = (e) => {
        this.setState({ new: e.target.value });
    }

    render() {
        if (this.state.success) {
            return (
                <div className="text-success"><Trans>auth-password-changed</Trans></div>
            );
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="title"><Trans>profile:block-password</Trans></div>
                <div>
                    <label className="row-label" htmlFor="old-password"><Trans>profile:old-password</Trans></label>
                    <div className="md-form md-outline mb-3 mt-0">
                        <input type="password" name="old-password" id="old-password" className="form-control" value={this.state.old} onChange={this.handleOldChange} required="required" minLength="10" />
                    </div>
                </div>
                <div>
                    <label className="row-label" htmlFor="new-password"><Trans>profile:new-password</Trans></label>
                    <div className="md-form md-outline mb-3 mt-0">
                        <input type="password" name="new-password" id="new-password" className="form-control" value={this.state.new} onChange={this.handleNewChange} required="required" minLength="10" autoComplete="new-password" />
                    </div>
                </div>
                <FormError message={this.state.error} />
                <button className="btn btn-success btn-block btn-rounded mt-2"><Trans>profile:block-password</Trans></button>
            </form>
        );
    }
}

class ProfilePageNotifications extends React.Component {
    constructor(pros) {
        super();
        this.state = {
            loading: true,
            items: []
        };
        this.saveDelay = 500;
        this.queue = {};
    }

    componentDidMount() {
        const _ = this;
        axios.post('/notification/subscriptions').then((response) => {
            const result = response.data;
            if (result.error) {
                console.log(result);
            }
            if (result.items && Array.isArray(result.items)) {
                _.setState({ items: result.items })
            }
        }).catch((error) => {
            const err = error.response.data;
        }).then(() => {
            _.setState({ loading: false });
        });
    }

    onChangeSubscriptionState = (e, name, index) => {
        const _ = this;
        var arr = this.state.items;
        arr[index][name] = e.target.checked;
        this.setState({
            items: arr
        });

        const timestamp = new Date().getTime();
        this.queue[arr[index].type] = timestamp;

        setTimeout(function () {
            _.saveNotificationsState(arr[index].type, timestamp);
        }, _.saveDelay);
    }

    saveNotificationsState(type, timestamp) {
        if (this.queue[type] !== timestamp) {
            return;
        }
        const data = this.state.items.find(x => x.type === type);
        axios.post('/notification/saveSubscription', data).then((response) => {
            const result = response.data;
            if (result.error) {
                console.log(result.error_description);
            }
        }).catch((error) => {
            const err = error.response.data;
            const res = Utils.checkRequestError(err);
            if (res) {
                //_.setState({ error: res });
                console.log('err', res);
                return;
            }
            console.log('err', err);
        });
    }

    testSendNotification(type) {
        var id = prompt('id', '0');
        axios.post('/notification/test', { type: type, id: id }).then((response) => {
            const result = response.data;
            console.log('testNotification', result);
        }).catch((error) => {
            const err = error.response.data;
            const res = Utils.checkRequestError(err);
            if (res) {
                console.log('err', res);
                return;
            }
            console.log('err', err);
        });
    }

    render() {//<div data-l10n-id={`notification-type-description-${item.type}`}></div>
        return (
            <div className="default-block p-3">
                <div className="profile-user-page-title">
                    <span><Trans>profile:notifications</Trans></span>
                </div>
                <div className="row">
                    {this.state.loading &&
                        <div className="col-12"><Loader /></div>
                    }
                    {!this.state.loading && this.state.items.length > 0 &&
                        <div className="col-12 my-4 notifications">
                            <div className="row d-none d-md-flex mt-n4">
                                <div className="col-6"></div>
                                <div className="col-2"><Trans>notification-push</Trans></div>
                                <div className="col-2"><Trans>notification-email</Trans></div>
                                <div className="col-2"><Trans>notification-site</Trans></div>
                            </div>
                            {this.state.items.map((item, index) => (
                                <div className="row py-2" key={item.type}>
                                    <div className="col-12 col-md-6">
                                        <div className="mb-2">
                                            <span className="font-weight-bold"><Trans>{`notification-type-title-${item.type}`}</Trans></span>
                                        </div>
                                        {this.props.admin &&
                                            <button className="btn btn-md btn-primary" onClick={() => this.testSendNotification(item.type)}><Trans>msg-test</Trans></button>
                                        }
                                    </div>
                                    <div className="col-4 d-md-none py-2"><Trans>notification-push</Trans></div>
                                    <div className="col-4 d-md-none py-2"><Trans>notification-email</Trans></div>
                                    <div className="col-4 d-md-none py-2"><Trans>notification-site</Trans></div>
                                    <div className="col-4 col-md-2">
                                        <div className="position-relative d-inline-block">
                                            <input type="checkbox" className="checkbox-itself" id={`cb_${item.type}_push`} checked={item.push ? 'checked' : ''} onChange={(e) => this.onChangeSubscriptionState(e, 'push', index)} />
                                            <label className="toggle" htmlFor={`cb_${item.type}_push`}></label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-2">
                                        <div className="position-relative d-inline-block">
                                            <input type="checkbox" className="checkbox-itself" id={`cb_${item.type}_email`} checked={item.email} onChange={(e) => this.onChangeSubscriptionState(e, 'email', index)} />
                                            <label className="toggle" htmlFor={`cb_${item.type}_email`}></label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-2">
                                        <div className="position-relative d-inline-block">
                                            <input type="checkbox" className="checkbox-itself" id={`cb_${item.type}_site`} checked={item.site} onChange={(e) => this.onChangeSubscriptionState(e, 'site', index)} />
                                            <label className="toggle" htmlFor={`cb_${item.type}_site`}></label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ProfilePage;