import { AppDispatch } from "../..";
import { Language } from "../../../enums/language";
import { UserService } from "../../../lmsApi/user/service";
import { User } from "../../../lmsApi/user/user";
import { AuthActionEnum, AuthParams, SetAuthParamsAction, SetLanguageAction, SetUserAction } from "./types";

export const AuthActionCreators = {
    setUser: (user: User | null): SetUserAction => ({ type: AuthActionEnum.SET_USER, payload: user }),
    setLanguage: (language: string): SetLanguageAction => ({ type: AuthActionEnum.SET_LANGUAGE, payload: language }),
    setAuthParams: (data: AuthParams): SetAuthParamsAction => ({ type: AuthActionEnum.SET_AUTH_PARAMS, payload: data }),
    auth: () => async (dispatch: AppDispatch) => {
        try {
            //dispatch(AuthActionCreators.setLoading(true));
            const data = await UserService.getCurrentUser();
            const language = await UserService.getLanguage();
            setTimeout(() => {
                dispatch(AuthActionCreators.setAuthParams({
                    language: language,
                    unviewedNotificationCount: data.unviewedNotifications ?? 0,
                    user: data.user
                }));
            }, 500);
        }
        catch (err) {
            console.error('auth error', err);
            //dispatch(AuthActionCreators.setLoading(false));
            dispatch(AuthActionCreators.setAuthParams({
                language: Language.ru,
                unviewedNotificationCount: 0,
                user: null
            }));
        }
    },
    changeLanguage: (language: string) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setLanguage(language));
        await UserService.saveLanguage(language);
    }
}
