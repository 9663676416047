import { FC } from "react";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useActions } from "../hooks/useActions";
import { Language } from "../enums/language";

interface IProps {
    showText?: boolean
}

const LanguageSelection: FC<IProps> = (props) => {
    const { language } = useTypedSelector(store => store.auth);
    const { changeLanguage } = useActions();
    const onClick = (e: any, lang: string) => {
        e.preventDefault();
        changeLanguage(lang);
    }
    return (
        <>
            {language === Language.en &&
                <a className={`${props.showText ? 'link-hover' : 'nav-link'} d-flex text-capitalize my-auto`} href="#" onClick={(e) => onClick(e, Language.ru)}>
                    <i className={'russia flag my-auto'}></i>
                    {props.showText &&
                        <span className="ms-2">{Language.ru}</span>
                    }
                </a>
            }
            {language === Language.ru &&
                <a className={`${props.showText ? 'link-hover' : 'nav-link'} d-flex text-capitalize my-auto`} href="#" onClick={(e) => onClick(e, Language.en)}>
                    <i className={'united kingdom flag my-auto'}></i>
                    {props.showText &&
                        <span className="ms-2">{Language.en}</span>
                    }
                </a>
            }
        </>
    );
}

export default LanguageSelection;
