import { LmsApi } from "..";
import { SelectValue } from "../../component/select/SelectValue";
import { Application } from "./application";

class CApplicationService {
    async list(requestData: any) {
        const data = await LmsApi.list<Application>('/application/list', requestData);
        return data;
    }

    async get(id: number) {
        const data = await LmsApi.post<Application>('/application/get', { id: id });
        return data;
    }

    async addOrUpdate(entity: Application, installActions: number[], uninstallActions: number[], defaultTariff?: number) {
        if (entity.id < 1) {
            return await LmsApi.post<number>('/application/add', { ...entity, installActions, uninstallActions, defaultTariff });
        }
        return await LmsApi.post<number>('/application/edit', { ...entity, installActions, uninstallActions, defaultTariff });
    }

    async delete(id: number[]) {
        const data = await LmsApi.post('/application/delete', { id: id });
        return data;
    }

    getDetailLink(entity: Application) {
        return `/app/edit/${entity.id}`;
    }

    async selectLoadOptions(inputValue: string): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                code: inputValue
            }
        };
        const data = await ApplicationService.list(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item.id}` ?? '',
                label: item.code ?? ''
            });
        });
        return arItems;
    }
}

export const ApplicationService = new CApplicationService();
