import { Redirect, Route, Switch } from 'react-router-dom';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import RestorePage from "../../../pages/restore";
import RegisterPage from '../register';
import LoginPage from '../login';

export default function AuthRoutes() {
    const { user } = useTypedSelector(store => store.auth);
    if (user) {
        return (
            <Redirect to="/" />
        );
    }
    return (
        <Switch>
            <Route path={`${AuthRoutesPath.Prefix}/login`} component={LoginPage}></Route>
            <Route path={`${AuthRoutesPath.Prefix}/register`} component={RegisterPage}></Route>
            <Route path={`${AuthRoutesPath.Prefix}/restore/:token`} component={RestorePage}></Route>
            <Route path={`${AuthRoutesPath.Prefix}/restore`} component={RestorePage}></Route>
        </Switch>
    );
}

export const AuthRoutesPath = {
    Prefix: '/auth'
};
