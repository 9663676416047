import { LmsApi } from "..";
import Utils from "../../utils";
import { User } from "./user";
import { Language } from '../../enums/language';
import { UserRole } from "../../enums/userRole";
import { SelectValue } from "../../component/select/SelectValue";

class CUserService {
    async list(requestData: any) {
        const data = await LmsApi.list<User>('/user/list', requestData);
        return data;
    }

    async get(id: number) {
        const data = await LmsApi.post<User>('/user/get', { id: id });
        return data;
    }

    async delete(id: number[]) {
        const data = await LmsApi.post('/user/delete', { id: id });
        return data;
    }

    getEditLink(entity: User) {
        return `/user/edit/${entity.id}`;
    }

    async getCurrentUser() {
        try {
            const data = await LmsApi.get<{ user: User, unviewedNotifications: number }>('/currentUser');
            return data;
        }
        catch (err) {
            //ErrorHandler.handle('getCurrentUser', err);
            return {
                user: null,
                unviewedNotifications: 0
            };
        }
    }

    getUserName(user: User): string {
        if (user.firstName && user.firstName.length > 0) {
            return `${user.firstName} ${user.lastName}`.trim();
        }
        if (user.lastName && user.lastName.length > 0) {
            return user.lastName;
        }
        return user.email;
    }

    getAvatarPath(user: User) {
        return user.avatar && user.avatar > 0 ? `/file/get/${user.avatar}` : '/images/profile-image120.png';
    }

    async getLanguage() {
        const lang = Utils.getFromLocalStorage('lang');
        console.log('getLanguage', lang);
        if (lang !== Language.en) {
            return Language.ru;
        }
        return Language.en;
    }

    async saveLanguage(language: string) {
        console.log('saveLanguage', language);
        Utils.saveToLocalStorage('lang', language);
    }

    async login(email: string, password: string) {
        return await LmsApi.post('/auth/login', { username: email, password: password });
    }

    async register(email: string, password: string) {
        return await LmsApi.post<{ result: boolean }>('/auth/register', {
            email: email,
            password: password
        });
    }

    async addAdminRole(user: User) {
        await LmsApi.post('/user/addAdminRole', { id: user.id });
    }

    async updateActive(userId: number, value: boolean) {
        return await LmsApi.post<boolean>('/user/updateActive', { id: userId, active: value ? 'Y' : 'N' });
    }

    isAdmin(user: User) {
        return user.roles && user.roles.includes(UserRole.admin);
    }

    async selectLoadOptions(inputValue: string): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                search: inputValue
            }
        };
        const data = await UserService.list(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item.id}` ?? '',
                label: UserService.getUserName(item)
            });
        });
        return arItems;
    }
}

export const UserService = new CUserService();
