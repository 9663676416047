import React, { FC, useMemo } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import TableComponent from "../../../component/table/tableComponent";
import { TableLoadData, HeaderItem } from "../../../component/table/tableComponent/types";
import { Log } from "../../../lmsApi/log/log";
import { LogService } from "../../../lmsApi/log/service";
import { UserService } from "../../../lmsApi/user/service";

interface IProps {
    filterValues?: { [key: string]: any }
}

const LogTable: FC<IProps> = (props) => {
    const header = useMemo<HeaderItem[]>(() => [
        {
            textId: 'main:field-id',
            field: 'id',
            sortable: true,
            getCellObject: function (item: Log) {
                return (<td className="align-middle">{item.id}</td>);
            }
        },
        {
            textId: 'main:field-type',
            field: 'type',
            alwaysVisible: true,
            getCellObject: function (item: Log) {
                return (<td className="align-middle"><Trans>{`main:log-type-${item.type}`}</Trans></td>);
            }
        },
        {
            textId: 'main:field-object-type',
            field: 'objectType',
            alwaysVisible: true,
            getCellObject: function (item: Log) {
                return (<td className="align-middle"><Trans>{`main:object-type-${item.objectType}`}</Trans></td>);
            }
        },
        {
            textId: 'main:field-object-id',
            field: 'objectId',
            getCellObject: function (item: Log) {
                return (
                    <td className="align-middle">
                        {item.objectId}
                    </td>
                );
            }
        },
        {
            textId: 'main:field-user',
            icon: 'fas fa-user-alt me-2',
            field: 'userId',
            getCellObject: function (item: Log) {
                const user = item.user ? item.user : false;
                return (
                    <td className="align-middle">
                        {user &&
                            <img src={UserService.getAvatarPath(user)} className="rounded-circle z-depth-0 avatar" alt="User Image" />
                        }
                        {user &&
                            <Link className="link" to={`/user/${user.id}`}>{UserService.getUserName(user)}</Link>
                        }
                    </td>
                );
            }
        },
        {
            textId: 'main:field-description',
            field: 'description',
            getCellObject: function (item: Log) {
                return (<td className="align-middle">{item.description}</td>);
            }
        },
        {
            textId: 'main:field-ip',
            field: 'ip',
            getCellObject: function (item: Log) {
                return (<td className="align-middle">{item.ip}</td>);
            }
        },
        {
            textId: 'main:field-date-and-time',
            field: 'createDate',
            getCellObject: function (item: Log) {
                return (
                    <td className="align-middle">{item.createDate}</td>
                );
            }
        }
    ], []);

    const loadData = async (data: TableLoadData) => {
        var requestData = {
            order: { [data.sortBy]: data.sortOrder.toUpperCase() },
            filter: { ...data.filterValues },
            skip: data.skip,
            take: data.take
        };
        const result = await LogService.list(requestData);
        return result;
    }

    return (
        <TableComponent id="admin-log-table-component" filterValues={props.filterValues} defaultVisibleColumns={['id', 'type', 'userId']} header={header}
            loadData={loadData} />
    );
}

export default LogTable;
