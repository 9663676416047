import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import Filter from '../../../component/filter';
import ActionTable from './table';
import { ActionRoutesPath } from "../routes";
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";

const RestrictionList: FC = () => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemHidden('name', { isTyping: 'Y' })
    ], []);

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="action:list-title" />
                <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                    <div className="app-filter-actions mb-2 mb-xl-0">
                        <Link className="btn btn-success px-3 waves-effect waves-light m-0 me-2" to={`${ActionRoutesPath.Prefix}/edit/0`}>{t('main:btn-add')}</Link>
                    </div>
                    <Filter id="filter-action" items={filterItems} onFind={setFilterValues} />
                </div>
                <ActionTable filterValues={filterValues} />
            </ContentWrapper>
        </>
    );
}

export default RestrictionList;
