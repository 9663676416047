import Utils from '../../utils';
import { Notification } from "./notification";

class CNotificationService {
    getData(notification: Notification) {
        const data = Utils.tryParseJson(notification.data) as { text: string, link?: { relative: string, href: string, text: string } };
        return data;
    }
}

export const NotificationService = new CNotificationService();
