import FilterItem from '..';
import FilterType from '../../filterType';
import { IDateRangeProps } from '../additionalParams';

class FilterItemDateRange extends FilterItem {
    /**
     * Выбор даты с типом периода
     * @param name поле сущности для фильтрации
     * @param title идентификатор названия
     */
    constructor(name: string, title: string, additionalParams?: IDateRangeProps) {
        super(FilterType.DateRange, name, title, additionalParams);
    }
}

export default FilterItemDateRange;
