import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import DictionaryList from '../list';

export default function DictionaryRoutes() {
    return (
        <Switch>
            <Route path={`${DictionaryRoutesPath.Prefix}/list`} component={DictionaryList} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const DictionaryRoutesPath = {
    Prefix: '/dictionary'
};
