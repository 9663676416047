import { LmsApi } from "..";
import { Log } from "./log";

class CLogService {
    async list(requestData: any) {
        const data = await LmsApi.list<Log>('/log/list', requestData);
        return data;
    }
}

export const LogService = new CLogService();
