import { LmsApi } from "..";
import { SelectValue } from "../../component/select/SelectValue";
import { FieldType } from "../../enums/fieldType";
import { Action } from "./action";
import { ActionKeyword, ActionParams, ActionParamSettings, ActionType } from "./types";

class CActionService {
    async list(requestData: any) {
        const data = await LmsApi.list<Action>('/action/list', requestData);
        return data;
    }

    async get(id: number) {
        const data = await LmsApi.post<Action>('/action/get', { id: id });
        return data;
    }

    async addOrUpdate(entity: Action) {
        if (entity.id < 1) {
            return await LmsApi.post<number>('/action/add', { ...entity });
        }
        return await LmsApi.post<number>('/action/edit', { ...entity });
    }

    async delete(id: number[]) {
        const data = await LmsApi.post('/action/delete', { id: id });
        return data;
    }

    getDetailLink(entity: Action) {
        return `/action/edit/${entity.id}`;
    }

    getCopyLink(entity: Action) {
        return `/action/edit/0?copy=${entity.id}`;
    }

    async selectLoadOptions(inputValue: string): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                code: inputValue
            }
        };
        const data = await ActionService.list(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item.id}` ?? '',
                label: item.name ?? ''
            });
        });
        return arItems;
    }

    /**
     * 
     * @param type тип ActionType
     * @returns Возвращает список доступных полей для действия в зависимости от его типа
     */
    getActionParamsByType(type?: string): ActionParamSettings[] {
        switch (type) {
            case ActionType.createDeal:
                return [
                    { name: ActionParams.webhookLink, type: FieldType.string, isRequired: true },
                    {
                        name: ActionParams.title, type: FieldType.string, isRequired: true, keywords: [
                            ActionKeyword.domain,
                            ActionKeyword.applicationCode
                        ]
                    },
                    { name: ActionParams.dealCategory, type: FieldType.string, isRequired: true },
                    { name: ActionParams.dealStage, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldDomain, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldAppCode, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldInternational, type: FieldType.string },
                    { name: ActionParams.userFieldNumberOfUsers, type: FieldType.string },
                    { name: ActionParams.userFieldNumberOfUsersList, type: FieldType.string }
                ];
            case ActionType.changeDealStatus:
                return [
                    { name: ActionParams.webhookLink, type: FieldType.string, isRequired: true },
                    { name: ActionParams.dealCategory, type: FieldType.string, isRequired: true },
                    { name: ActionParams.dealStage, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldDomain, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldAppCode, type: FieldType.string, isRequired: true }
                ];
            case ActionType.createLead:
                return [
                    { name: ActionParams.webhookLink, type: FieldType.string, isRequired: true },
                    {
                        name: ActionParams.title, type: FieldType.string, isRequired: true, keywords: [
                            ActionKeyword.domain,
                            ActionKeyword.applicationCode
                        ]
                    },
                    { name: ActionParams.leadStatus, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldDomain, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldAppCode, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldNumberOfUsers, type: FieldType.string },
                    { name: ActionParams.userFieldNumberOfUsersList, type: FieldType.string }
                ];
            case ActionType.changeLeadStatus:
                return [
                    { name: ActionParams.webhookLink, type: FieldType.string, isRequired: true },
                    { name: ActionParams.leadStatus, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldDomain, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldAppCode, type: FieldType.string, isRequired: true }
                ];
            case ActionType.createContact:
                return [
                    { name: ActionParams.webhookLink, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldNumberOfUsers, type: FieldType.string },
                    { name: ActionParams.userFieldNumberOfUsersList, type: FieldType.string }
                ];
            case ActionType.getNumberOfUsers:
                return [
                    { name: ActionParams.usersOnlyActive, type: FieldType.bool, isRequired: true }
                ];
            case ActionType.updateActivityDate:
                return [
                    { name: ActionParams.webhookLink, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldActivityDate, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldDomain, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldAppCode, type: FieldType.string, isRequired: true }
                ];
            case ActionType.updateActiveUsersCount:
                return [
                    { name: ActionParams.webhookLink, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldActiveUsersCount, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldDomain, type: FieldType.string, isRequired: true },
                    { name: ActionParams.userFieldAppCode, type: FieldType.string, isRequired: true }
                ];
        }
        return [];
    }
}

export const ActionService = new CActionService();
