import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { MDBInput } from 'mdb-react-ui-kit';
import FormError from "../component/formError";
import LanguageSelection from '../component/languageSelection';
import { AuthRoutesPath } from '../page/auth/routes';

class RestorePage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            error: '',
            email: '',
            token: '',
            password: '',
            step: 1,
            tokenValid: false
        };
        this.btnSubmit = React.createRef();
    }

    componentDidMount() {
        if (this.props.match.params.token) {
            this.setState({
                token: this.props.match.params.token,
                step: 2
            }, () => {
                this._handleSubmit();
            });
        }
        //console.log('mount', this.props.match.params.token);
    }

    _handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    _handleTokenChange = (e) => {
        this.setState({ token: e.target.value });
    }

    _handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
    }

    _handleSubmit = (e) => {
        if (e) e.preventDefault();
        switch (this.state.step) {
            case 1:
                this._requestEmail();
                break;
            case 2:
                this._requestTokenValidation();
                break;
            case 3:
                this._requestResetPassword();
                break;
            default:
                break;
        }
    }

    _requestEmail() {
        const _ = this;
        _.btnSubmit.current.setAttribute("disabled", "disabled");
        axios.post('/auth/restore', { email: this.state.email }).then(function (response) {
            const result = response.data;
            _.btnSubmit.current.removeAttribute("disabled");
            console.log('auth/restore result', result);
            if (result.error) {
                _.setState({ error: result.error_description });
            }
            else {
                _.setState({ error: '', step: 2 });
            }
        }).catch(error => {
            const err = error.response.data;
            _.btnSubmit.current.removeAttribute("disabled");
            console.error('auth err', err);
            _.setState({ error: 'main-error-server-not-responding' });
        });
    }

    _requestTokenValidation() {
        const _ = this;
        _.btnSubmit.current.setAttribute("disabled", "disabled");
        axios.post('/auth/restoreTokenValidation', { token: this.state.token }).then(function (response) {
            const result = response.data;
            _.btnSubmit.current.removeAttribute("disabled");
            console.log('auth/restoreToken result', result);
            if (result.error) {
                _.setState({ error: result.error_description });
            }
            else {
                _.setState({ error: '', step: 3, email: result.user.email });
            }
        }).catch(error => {
            const err = error.response.data;
            _.btnSubmit.current.removeAttribute("disabled");
            console.error('auth err', err);
            _.setState({ error: 'main-error-server-not-responding' });
        });
    }

    _requestResetPassword() {
        const _ = this;
        _.btnSubmit.current.setAttribute("disabled", "disabled");
        axios.post('/auth/resetPassword', { token: this.state.token, password: this.state.password }).then(function (response) {
            const result = response.data;
            _.btnSubmit.current.removeAttribute("disabled");
            console.log('auth/resetPassword result', result);
            if (result.error) {
                _.setState({ error: result.error_description });
            }
            else {
                _.setState({ error: '', step: 4 });
            }
        }).catch(error => {
            const err = error.response.data;
            _.btnSubmit.current.removeAttribute("disabled");
            console.error('auth err', err);
            _.setState({ error: 'main-error-server-not-responding' });
        });
    }

    render() {
        return (
            <div className="h-100 d-flex justify-content-center align-items-center">
                <form className="text-center auth-form" onSubmit={this._handleSubmit}>
                    <p className="h4 mt-4 mb-lg-4"><Trans>auth:restore-title</Trans></p>
                    {this.state.step === 1 &&
                        <React.Fragment>
                            <p className="h6 mt-2 mb-1"><Trans>auth:restore-description</Trans></p>
                            <section className="row px-lg-5">
                                <div className="col-12 px-lg-5 mb-4 mt-3">
                                    <MDBInput type="email" name="current-email" id="current-email" value={this.state.email} label={i18next.t('auth:field-email')} onChange={this._handleEmailChange} required="required" size="lg" />
                                    <FormError message={this.state.error} />
                                </div>
                            </section>
                        </React.Fragment>}
                    {this.state.step === 2 &&
                        <React.Fragment>
                            <p className="h6 mt-2 mb-1"><Trans>auth:restore-token-description</Trans></p>
                            <section className="row px-lg-5">
                                <div className="col-12 px-lg-5 mb-4 mt-3">
                                    <MDBInput type="text" name="token" id="token" value={this.state.token} label={i18next.t('auth:field-token')} onChange={this._handleTokenChange} required="required" size="lg" autoComplete="off" />
                                    <FormError message={this.state.error} />
                                </div>
                            </section>
                        </React.Fragment>
                    }
                    {this.state.step === 3 &&
                        <React.Fragment>
                            <p className="h6 mt-2 mb-1"><Trans>auth:restore-password-description</Trans></p>
                            <section className="row px-lg-5">
                                <div className="col-12 px-lg-5 mb-4 mt-3">
                                    <MDBInput type="email" name="username" id="current-email" value={this.state.email} label={i18next.t('auth:field-email')} required="required" size="lg" readonly />
                                </div>
                                <div className="col-12 px-lg-5 mb-4 mt-3">
                                    <MDBInput type="password" name="new-password" id="new-password" value={this.state.password} label={i18next.t('auth:field-password')} onChange={this._handlePasswordChange} required="required" size="lg" minLength="new-password" />
                                    <FormError message={this.state.error} />
                                </div>
                            </section>
                        </React.Fragment>
                    }
                    {this.state.step === 4 &&
                        <React.Fragment>
                            <p className="py-3 px-5 text-success"><Trans>auth:password-changed</Trans></p>
                        </React.Fragment>
                    }
                    {this.state.step !== 4 &&
                        <section className="row">
                            <div className="col-12 mb-4">
                                <button className="btn btn-success btn-rounded font-weight-bold px-5" type="submit" ref={this.btnSubmit}>
                                    <span>{this.state.step < 3 ? <Trans>auth:restore-get-password</Trans> : <Trans>main:btn-save</Trans>}</span>
                                </button>
                            </div>
                        </section>
                    }
                    <section className="row px-4">
                        <div className="col-12 col-md-5 mb-2">
                            <Link className="link-hover" to={`${AuthRoutesPath.Prefix}/register`}><Trans>auth:sign-up</Trans></Link>
                        </div>
                        <div className="col-12 col-md-4 mb-2">
                            <Link className="link-hover" to={`${AuthRoutesPath.Prefix}/login`}><Trans>auth:sign-in-action</Trans></Link>
                        </div>
                        <div className="col-12 col-md-3 mb-2">
                            <LanguageSelection showText={true} />
                        </div>
                    </section>
                </form>
            </div>
        );
    }
}

export default RestorePage;
