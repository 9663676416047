import { AppDispatch } from "../..";
import Utils from "../../../utils";
import { LayoutActionEnum, SetTitleAction, SetBacklinkAction, SetMenuOpenedAction, SetMenuCollapsedAction } from "./types";

export const LayoutActionCreators = {
    setTitle: (title: string): SetTitleAction => ({ type: LayoutActionEnum.SET_TITLE, payload: title }),
    setBacklink: (backlink?: string): SetBacklinkAction => ({ type: LayoutActionEnum.SET_BACKLINK, payload: backlink }),
    setMenuOpened: (value: boolean): SetMenuOpenedAction => ({ type: LayoutActionEnum.SET_MENU_OPENED, payload: value }),
    setMenuCollapsed: (value: boolean): SetMenuCollapsedAction => ({ type: LayoutActionEnum.SET_MENU_COLLAPSED, payload: value }),
    toggleMenu: (value: boolean) => async (dispatch: AppDispatch) => {

        dispatch(LayoutActionCreators.setMenuCollapsed(value));
        dispatch(LayoutActionCreators.setMenuOpened(false));

        Utils.saveToLocalStorage('menuCollapsed', value ? 'Y' : 'N');
    },
    initMenu: () => async (dispatch: AppDispatch) => {
        const value = Utils.getFromLocalStorage('menuCollapsed');
        if (value === 'Y') {
            dispatch(LayoutActionCreators.setMenuCollapsed(true));
        }
    }
}
