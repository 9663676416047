import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MDBBtn, MDBCheckbox, MDBInput } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import DefaultBlock from '../../../component/layout/defaultBlock';
import InputContainer from '../../../component/layout/inputContainer';
import ContentWrapper from "../../../component/contentWrapper";
import Header from "../../../component/layout/header";
import PageTitle from "../../../component/pageTitle";
import { ErrorHandler } from "../../../service/errorHandler";
import { ToastManager } from "../../../service/toastManager";
import { Action } from "../../../lmsApi/action/action";
import { ActionService } from "../../../lmsApi/action/service";
import { ActionRoutesPath } from "../routes";
import ActionTypeSelect from "../../../component/select/items/actionType";
import { SelectValue } from "../../../component/select/SelectValue";
import Utils from "../../../utils";
import { FieldType } from "../../../enums/fieldType";

const ActionEdit: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const { id } = useParams<{ id?: string }>();
    const [type, setType] = useState<SelectValue | null>(null);
    const [data, setData] = useState<{ [key: string]: string }>({});

    const [entity, setEntity] = useState<Action>({
        id: id ? (parseInt(id) || 0) : 0,
        name: ''
    });

    useEffect(() => {
        refreshData();
    }, [entity.id]);

    const refreshData = async () => {
        const urlParams = new URLSearchParams(search);
        const copyId = parseInt(urlParams.get('copy') ?? '') || 0;
        if (entity.id < 1 && copyId < 1) {
            return;
        }

        try {
            const result = await ActionService.get(entity.id < 1 ? copyId : entity.id);
            if (result) {
                setEntity({
                    ...result,
                    id: entity.id < 1 ? 0 : entity.id
                });
                if (result.type && result.type.length > 0) {
                    setType({ value: `${result.type}`, label: t(`action:type-${result.type}`) });
                }
                const jsonData = Utils.tryParseJson<{ [key: string]: string }>(result.data ?? '');
                if (jsonData) {
                    setData(jsonData);
                }
            }
        }
        catch (err) {
            ErrorHandler.handle('action edit refreshData', err);
        }
    }

    const onChangeData = (key: string, value: string) => {
        setData({ ...data, [key]: value });
    }

    const dataActions = ActionService.getActionParamsByType(type?.value);

    /**
     * Удаляет лишние ключи при сохранении
     * @returns 
     */
    const prepareData = () => {
        const result: { [key: string]: string } = {};
        dataActions.forEach(item => {
            result[item.name] = data[item.name];
        });
        return result;
    }

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            if (!type) {
                return;
            }
            const preparedData = prepareData();
            const result = await ActionService.addOrUpdate({ ...entity, type: type.value, data: JSON.stringify(preparedData) });
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (entity.id < 1) {
                history.push(`${ActionRoutesPath.Prefix}/edit/${result}`);
            }
            console.log('result', result);
            setEntity({ ...entity, id: result });
        }
        catch (err) {
            ErrorHandler.handle('on save action', err);
        }
    }

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId={entity.id < 1 ? 'action:add-title' : 'action:edit-title'} />
                <DefaultBlock>
                    <form onSubmit={onSubmit}>
                        <InputContainer>
                            <MDBInput type="text" size="lg" value={entity.name} name='name' onChange={(e: any) => setEntity({ ...entity, name: e.target.value })} required label={t('action:name')} />
                        </InputContainer>
                        <InputContainer>
                            <ActionTypeSelect className="select-lg" value={type} onChange={setType} title="main:field-type" required />
                        </InputContainer>
                        {dataActions.map(dataItem => (
                            <InputContainer key={dataItem.name}>
                                {dataItem.type === FieldType.string &&
                                    <>
                                        <MDBInput size="lg" value={data[dataItem.name] ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeData(dataItem.name, e.target.value)} required={dataItem.isRequired} label={t(`action:data-name-${dataItem.name}`)} />
                                        {dataItem.keywords &&
                                            <>
                                                <div>{t('action:acceptable-keywords')}</div>
                                                {dataItem.keywords.map(keyword => (
                                                    <div key={keyword}>
                                                        <MDBBtn type="button" color="link" onClick={() => onChangeData(dataItem.name, `${data[dataItem.name] ?? ''}${`#${keyword}#`}`)}>#{keyword}#</MDBBtn>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </>
                                }
                                {dataItem.type === FieldType.bool &&
                                    <>
                                        <MDBCheckbox size="lg" id={`cb-${dataItem.name}`} checked={data[dataItem.name] === 'Y'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeData(dataItem.name, e.target.checked ? 'Y' : 'N')} label={t(`action:data-name-${dataItem.name}`)} />
                                    </>
                                }
                            </InputContainer>
                        ))}
                        <div>
                            <MDBBtn type="submit" color="primary">{t('main:btn-save')}</MDBBtn>
                        </div>
                    </form>
                </DefaultBlock>
            </ContentWrapper>
        </>
    )
}

export default ActionEdit;
