import { IAdditionalParams, IAsyncSelectProps, ISelectProps, IDateRangeProps, IHiddenProps, INumberRangeProps, ITextProps } from './additionalParams';

class FilterItem {
    Type: string;
    Name: string;
    Title: string;
    ContainerClassName: string = '';
    additionalParams?: IAdditionalParams;

    constructor(type: string, name: string, title: string, additionalParams?: ISelectProps | IAsyncSelectProps | IDateRangeProps | IHiddenProps | INumberRangeProps | ITextProps) {
        this.Type = type;
        this.Name = name;
        this.Title = title;
        this.ContainerClassName = '';
        this.additionalParams = additionalParams;
    }
}

export default FilterItem;
