import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import List from '../list';

export default function UserRoutes() {
    return (
        <Switch>
            <Route path={`${UserRoutesPath.Prefix}/list`} component={List} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const UserRoutesPath = {
    Prefix: '/user'
};
