import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import { TariffRoutesPath } from "../../tariff/routes";
import { RestrictionRoutesPath } from "../../restriction/routes";
import { ApplicationRoutesPath } from "../../application/routes";
import { ActionRoutesPath } from "../../action/routes";

const DictionaryList: FC = () => {
    const { t } = useTranslation();
    const items = useMemo(() => [
        { link: `${TariffRoutesPath.Prefix}/list`, name: 'tariff:menu-title', icon: 'mx-auto fas fa-hand-holding-usd fa-5x' },
        { link: `${ApplicationRoutesPath.Prefix}/list`, name: 'application:menu-title', icon: 'mx-auto fab fa-app-store fa-5x' },
        { link: `${RestrictionRoutesPath.Prefix}/list`, name: 'restriction:menu-title', icon: 'mx-auto fas fa-ban fa-5x' },
        { link: `${ActionRoutesPath.Prefix}/list`, name: 'action:menu-title', icon: 'mx-auto fab fa-galactic-republic fa-5x' }
    ], []);
    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="dictionary:list-title" />
                <div className="row">
                    {items.map((item) => (
                        <div key={item.link} className="col-12 col-md-6 col-xl-4 mb-2">
                            <NavLink className="default-block admin-block p-4 d-flex" to={item.link}>
                                <div className="w-100 d-flex flex-column">
                                    <i className={item.icon}></i>
                                    <span className="mx-auto mt-3">{t(item.name)}</span>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                </div>
            </ContentWrapper>
        </>
    );
}

export default DictionaryList;
