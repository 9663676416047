import { createReducer } from '@reduxjs/toolkit';
import { LayoutActionEnum, LayoutState, SetBacklinkAction, SetMenuCollapsedAction, SetMenuOpenedAction, SetTitleAction } from './types';

const initialState: LayoutState = {
    pageTitle: '',
    menuOpened: false,
    menuCollapsed: false,
}

const layoutReducer = createReducer(initialState, {
    [LayoutActionEnum.SET_TITLE]: (state, action: SetTitleAction) => {
        return { ...state, pageTitle: action.payload };
    },
    [LayoutActionEnum.SET_BACKLINK]: (state, action: SetBacklinkAction) => {
        return { ...state, backLink: action.payload };
    },
    [LayoutActionEnum.SET_MENU_OPENED]: (state, action: SetMenuOpenedAction) => {
        return { ...state, menuOpened: action.payload };
    },
    [LayoutActionEnum.SET_MENU_COLLAPSED]: (state, action: SetMenuCollapsedAction) => {
        return { ...state, menuCollapsed: action.payload };
    },
});

export default layoutReducer;
