import React from "react";

export const Languages = ['ru', 'en'];

const defaultUserContext = {
    user: false,
    isAdmin: () => { },
    isModerator: () => { return false },
    language: Languages[0],
    menuCollapsed: false,
    toggleMenu: () => { },
    menuOpened: false,
    openMenu: () => { },
    getProgramsById: (ids, callback) => { },
    unviewedNotificationCount: 0,
    lastWifiList: {},
    clearWifiScan: () => { },
    wifiRefresh: false,
    clearWifiRefresh: () => { },
    refreshUser: (callback) => { },
    isRegisterComplete: false,
    setIsRegisterComplete: () => { }
};

export const UserContext = React.createContext(defaultUserContext);