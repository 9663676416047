import React, { FC, useEffect, useState } from "react";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router";
import DefaultBlock from "../../../component/layout/defaultBlock";
import InputContainer from "../../../component/layout/inputContainer";
import ContentWrapper from "../../../component/contentWrapper";
import Header from "../../../component/layout/header";
import PageTitle from "../../../component/pageTitle";
import { Licence } from "../../../lmsApi/licence/licence";
import { LicenceService } from "../../../lmsApi/licence/service";
import { ErrorHandler } from "../../../service/errorHandler";
import { ToastManager } from "../../../service/toastManager";
import TariffSelect from "../../../component/select/items/tariff";
import ApplicationSelect from "../../../component/select/items/application";
import ClientSelect from "../../../component/select/items/client";
import { SelectValue } from "../../../component/select/SelectValue";
import ViewField from "../../../component/layout/viewField";
import { LicenceRoutesPath } from "../routes";
import { ClientService } from "../../../lmsApi/client/service";
import moment from "moment";
import { TariffService } from "../../../lmsApi/tariff/service";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const LicenceEdit: FC = () => {
    const { t } = useTranslation();
    const { language } = useTypedSelector(store => store.auth);
    const history = useHistory();
    const { search } = useLocation();
    const { id } = useParams<{ id?: string }>();
    const [client, setClient] = useState<SelectValue | null>(null);
    const [application, setApplication] = useState<SelectValue | null>(null);
    const [tariff, setTariff] = useState<SelectValue | null>(null);

    const [entity, setEntity] = useState<Licence>({
        id: id ? parseInt(id) || 0 : 0,
        createDate: "",
    });

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        const urlParams = new URLSearchParams(search);
        const copyId = parseInt(urlParams.get("copy") ?? "") || 0;
        const clientId = parseInt(urlParams.get("client") ?? "") || 0;
        if (entity.id < 1 && copyId < 1 && clientId < 1) {
            return;
        }
        try {
            if (clientId > 0) {
                await initDataByClientId(clientId);
                return;
            }

            const result = await LicenceService.get(entity.id < 1 ? copyId : entity.id);
            if (result) {
                if (entity.id > 0) {
                    setEntity({
                        ...result,
                    });
                }
                setClient(result.client ? { label: result.client.domain, value: `${result.client.id}` } : null);
                setApplication(result.application ? { label: result.application.code, value: `${result.application.id}` } : null);
                setTariff(result.tariff ? { label: TariffService.getName(result.tariff, language), value: `${result.tariff.id}` } : null);
            }
        } catch (err) {
            ErrorHandler.handle("licence edit refreshData", err);
        }
    };

    const initDataByClientId = async (clientId: number) => {
        const data = await ClientService.get(clientId);
        if (data) {
            setClient(data ? { label: data.domain, value: `${data.id}` } : null);
        }
    };

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            const result = await LicenceService.addOrUpdate(
                entity,
                client ? Number(client.value) : undefined,
                application ? Number(application.value) : undefined,
                tariff ? Number(tariff.value) : undefined
            );
            ToastManager.success({ title: "main:msg-success", message: "main:msg-changes-saved" });
            if (entity.id < 1) {
                history.push(`${LicenceRoutesPath.Prefix}/edit/${result}`);
            }
            setEntity({ ...entity, id: result });
            refreshData();
        } catch (err) {
            ErrorHandler.handle("on save app", err);
        }
    };

    const onChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("onChangeStartDate", e.target.value);
        setEntity({
            ...entity,
            startDate: e.target.value ? new Date(e.target.value).toISOString() : undefined,
        });
    };

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId={entity.id < 1 ? "licence:add-title" : "licence:edit-title"} />
                <DefaultBlock>
                    <form onSubmit={onSubmit}>
                        <InputContainer>
                            <ClientSelect value={client} onChange={setClient} isClearable required title="licence:client" />
                        </InputContainer>
                        <InputContainer>
                            <ApplicationSelect value={application} onChange={setApplication} isClearable required title="licence:application" />
                        </InputContainer>
                        <InputContainer>
                            <TariffSelect value={tariff} onChange={setTariff} isClearable required title="licence:tariff" />
                        </InputContainer>
                        <InputContainer>
                            <MDBInput
                                type="datetime-local"
                                value={entity.startDate ? moment(entity.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL) : " "}
                                size="lg"
                                onChange={onChangeStartDate}
                                label={t("licence:start-date")}
                            />
                        </InputContainer>
                        <InputContainer>
                            <MDBInput
                                type="text"
                                size="lg"
                                value={entity.payment ?? ""}
                                name="name"
                                onChange={(e: any) => setEntity({ ...entity, payment: e.target.value })}
                                label={t("licence:payment")}
                            />
                        </InputContainer>
                        {entity.createDate && entity.createDate.length > 0 && (
                            <ViewField title="main:create-date">{new Date(entity.createDate).toLocaleString()}</ViewField>
                        )}
                        {entity.updateDate && entity.updateDate.length > 0 && (
                            <ViewField title="main:update-date">{new Date(entity.updateDate).toLocaleString()}</ViewField>
                        )}
                        <div>
                            <MDBBtn type="submit" color="primary">
                                {t("main:btn-save")}
                            </MDBBtn>
                        </div>
                    </form>
                </DefaultBlock>
            </ContentWrapper>
        </>
    );
};

export default LicenceEdit;
