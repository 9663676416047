import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { UserService } from "../lmsApi/user/service";
import { User } from "../lmsApi/user/user";
import { ErrorHandler } from "../service/errorHandler";

interface IProps {
    user: User
}

const UserActiveValue: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { user } = useTypedSelector(store => store.auth);
    const [userId, setUserId] = useState(props.user.id);
    const [active, setActive] = useState(props.user.active === 'Y');

    const onClickChangeState = async (e: React.MouseEvent<HTMLElement>) => {
        if (e) e.preventDefault();
        try {
            const value = !active;
            await UserService.updateActive(userId, value);
            setActive(value);
        }
        catch (err) {
            ErrorHandler.handle('change active', err);
        }
    }

    return (
        <>
            <span>{t(active ? 'main:yes' : 'main:no')}</span>
            {user && user.id != userId && UserService.isAdmin(user) &&
                <a className="ms-2 link no-user-select link-hover" href="#" onClick={onClickChangeState}>{t(active ? 'main:btn-deactivate' : 'main:btn-activate')}</a>
            }
        </>
    );
}

export default UserActiveValue;
