import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import ClientList from '../list';
import ClientView from '../view';
import ClientEdit from '../edit';

export default function ClientRoutes() {
    return (
        <Switch>
            <Route path={`${ClientRoutesPath.Prefix}/list`} component={ClientList} />
            <Route path={`${ClientRoutesPath.Prefix}/edit/:id`} component={ClientEdit} />
            <Route path={`${ClientRoutesPath.Prefix}/view/:id`} component={ClientView} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const ClientRoutesPath = {
    Prefix: '/client'
};
