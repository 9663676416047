import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import AdminPanel from '../adminPanel';
import LogList from '../log';

export default function AdminRoutes() {
    return (
        <Switch>
            <Route path={`${AdminRoutesPath.Prefix}`} exact component={AdminPanel} />
            <Route path={`${AdminRoutesPath.Prefix}/log`} component={LogList} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const AdminRoutesPath = {
    Prefix: '/admin'
};
