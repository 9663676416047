import { FC, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import i18n from './i18n';
import Modal from 'react-modal';
import { useActions } from './hooks/useActions';
import { useTypedSelector } from './hooks/useTypedSelector';
import Layout from './component/layout';
import SplashScreen from './component/layout/splashScreen';

Modal.setAppElement('#root');

const App: FC = () => {
    const { language, isFirstLoadComplete } = useTypedSelector(state => state.auth);
    const { auth, initMenu } = useActions();

    useEffect(() => {
        auth();
        initMenu();
    }, []);

    useEffect(() => {
        if (!language) {
            return;
        }
        i18n.changeLanguage(language);
    }, [language]);

    // const checkAccess = (key: string): boolean => {
    //     if (!user)
    //         return false;

    //     return rules.includes(key);
    // }

    return (
        <>
            {!isFirstLoadComplete
                ? <SplashScreen />
                : <Layout />
            }
            <ToastContainer />
        </>
    )

    //   return (
    //     <UserContext.Provider value={{
    //       //language: this.state.lang,
    //       //checkAccess: checkAccess
    //     }}>

    //     </UserContext.Provider >
    //   );
}

export default App;
