import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import mainRu from './page/home/locales/ru.json';
import mainEn from './page/home/locales/en.json';
import tableRu from './component/table/locales/ru.json';
import tableEn from './component/table/locales/en.json';
import filterEn from './component/filter/locales/en.json';
import filterRu from './component/filter/locales/ru.json';
import userRu from './page/user/locales/ru.json';
import userEn from './page/user/locales/en.json';
import authRu from './page/auth/locales/ru.json';
import authEn from './page/auth/locales/en.json';
import applicationEn from './page/application/locales/en.json';
import applicationRu from './page/application/locales/ru.json';
import clientRu from './page/client/locales/ru.json';
import clientEn from './page/client/locales/en.json';
import dictionaryRu from './page/dictionary/locales/ru.json';
import dictionaryEn from './page/dictionary/locales/en.json';
import adminRu from './page/admin/locales/ru.json';
import adminEn from './page/admin/locales/en.json';
import profileRu from './page/profile/locales/ru.json';
import profileEn from './page/profile/locales/en.json';
import restrictionRu from './page/restriction/locales/ru.json';
import restrictionEn from './page/restriction/locales/en.json';
import tariffRu from './page/tariff/locales/ru.json';
import tariffEn from './page/tariff/locales/en.json';
import licenceRu from './page/licence/locales/ru.json';
import licenceEn from './page/licence/locales/en.json';
import actionRu from './page/action/locales/ru.json';
import actionEn from './page/action/locales/en.json';

const resources = {
    en: {
        main: mainEn,
        table: tableEn,
        filter: filterEn,
        client: clientEn,
        dictionary: dictionaryEn,
        admin: adminEn,
        auth: authEn,
        profile: profileEn,
        application: applicationEn,
        restriction: restrictionEn,
        tariff: tariffEn,
        licence: licenceEn,
        user: userEn,
        action: actionEn
    },
    ru: {
        main: mainRu,
        table: tableRu,
        filter: filterRu,
        client: clientRu,
        dictionary: dictionaryRu,
        admin: adminRu,
        auth: authRu,
        profile: profileRu,
        application: applicationRu,
        restriction: restrictionRu,
        tariff: tariffRu,
        licence: licenceRu,
        user: userRu,
        action: actionRu
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        supportedLngs: ['ru', 'en'],
        fallbackLng: false,
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;
