import React, { FC, useEffect, useState } from "react";
import DefaultBlock from '../../../component/layout/defaultBlock';
import InputContainer from '../../../component/layout/inputContainer';
import ContentWrapper from "../../../component/contentWrapper";
import Header from "../../../component/layout/header";
import PageTitle from "../../../component/pageTitle";
import { useHistory, useParams } from "react-router";
import { Client } from "../../../lmsApi/client/client";
import { ClientService } from "../../../lmsApi/client/service";
import { ErrorHandler } from "../../../service/errorHandler";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { ToastManager } from "../../../service/toastManager";

const ClientEdit: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();

    const [entity, setEntity] = useState<Client>({
        id: id ? (parseInt(id) || 0) : 0,
        domain: ''
    });

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        if (entity.id < 1) {
            return;
        }

        try {
            const result = await ClientService.get(entity.id);
            if (result) {
                setEntity({
                    ...result,
                });
            }
        }
        catch (err) {
            ErrorHandler.handle('clientEdit refreshData', err);
        }
    }

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {

            const result = await ClientService.addOrUpdate(entity);
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (entity.id < 1) {
                history.push(`/client/edit/${result}`);
            }
            setEntity({ ...entity, id: result });
            refreshData();
        }
        catch (err) {
            ErrorHandler.handle('on save app', err);
        }
    }

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId={entity.id < 1 ? 'client:add-title' : 'client:edit-title'} />
                <DefaultBlock>
                    <form onSubmit={onSubmit}>
                        <InputContainer>
                            <MDBInput type="text" size="lg" value={entity.domain} name='domain' onChange={(e: any) => setEntity({ ...entity, domain: e.target.value })} required label={t('client:domain')} />
                        </InputContainer>
                        <div>
                            <MDBBtn type="submit" color="primary">{t('main:btn-save')}</MDBBtn>
                        </div>
                    </form>
                </DefaultBlock>
            </ContentWrapper>
        </>
    )
}

export default ClientEdit;
