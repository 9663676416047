import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from '../../../component/layout/header';
import PageTitle from '../../../component/pageTitle';
import ContentWrapper from '../../../component/contentWrapper';
import Filter from '../../../component/filter';
import UserTable from './table';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import FilterItemAsyncSelect from "../../../component/filter/types/filterItem/items/asyncSelect";
import { UserService } from "../../../lmsApi/user/service";
import FilterItemSelect from "../../../component/filter/types/filterItem/items/select";

const ClientList: FC = () => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemHidden('email', { isTyping: 'Y' }),
        new FilterItemAsyncSelect('id', 'main:field-user', { isDefault: "Y", loadOptions: UserService.selectLoadOptions }),
        new FilterItemSelect('active', 'main:field-active', { isDefault: "Y", options: [{ value: 'Y', label: t('main:yes') }, { value: 'N', label: t('main:no') }] })
    ], []);

    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId="user:menu-title" />
                <div className="d-lg-flex mb-1 mb-lg-3 mt-3">
                    <Filter id="filter-users" items={filterItems} onFind={setFilterValues} />
                </div>
                <UserTable filterValues={filterValues} />
            </ContentWrapper>
        </>
    );
}

export default ClientList;
