import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActionType } from "../../../lmsApi/action/types";
import AppAsyncSelect from '../appAsyncSelect';
import { ISelectProps } from "../SelectProps";
import { SelectValue } from "../SelectValue";

const ActionTypeSelect: FC<ISelectProps> = (props) => {
    const { t } = useTranslation();
    const initTypeOptions = () => {
        const result: SelectValue[] = [];
        for (const key of Object.values(ActionType)) {
            result.push({
                value: key,
                label: t(`action:type-${key}`)
            });
        }
        return result;
    }
    const typeOptions = initTypeOptions();//useMemo<SelectValue[]>(initTypeOptions, []);

    const { options, ...newProps } = props;
    return (
        <AppAsyncSelect {...newProps} options={options ?? typeOptions} />
    );
}

export default ActionTypeSelect;
