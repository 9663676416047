import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MDBBtn, MDBCheckbox, MDBInput } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import DefaultBlock from '../../../component/layout/defaultBlock';
import InputContainer from '../../../component/layout/inputContainer';
import ContentWrapper from "../../../component/contentWrapper";
import Header from "../../../component/layout/header";
import PageTitle from "../../../component/pageTitle";
import { ErrorHandler } from "../../../service/errorHandler";
import { ToastManager } from "../../../service/toastManager";
import { Tariff } from "../../../lmsApi/tariff/tariff";
import { TariffService } from "../../../lmsApi/tariff/service";
import { TariffRoutesPath } from "../routes";
import RestrictionSelect from "../../../component/select/items/restriction";
import { SelectValue } from "../../../component/select/SelectValue";
import { Language } from "../../../enums/language";

const TariffEdit: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();
    const [restrictions, setRestrictions] = useState<SelectValue[]>([]);
    const [entity, setEntity] = useState<Tariff>({
        id: id ? (parseInt(id) || 0) : 0,
        active: "Y",
        restrictions: [],
        data: []
    });

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        if (entity.id < 1) {
            return;
        }

        try {
            const result = await TariffService.get(entity.id);
            if (result) {
                setEntity({
                    ...result,
                });
                const items = result.restrictions.map(x => {
                    return {
                        label: x.name,
                        value: `${x.id}`
                    }
                });
                setRestrictions(items);
            }
        }
        catch (err) {
            ErrorHandler.handle('tariff edit refreshData', err);
        }
    }

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            const result = await TariffService.addOrUpdate(entity, restrictions.map(x => Number(x.value)));
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (entity.id < 1) {
                history.push(`${TariffRoutesPath.Prefix}/edit/${result}`);
            }
            setEntity({ ...entity, id: result });
            refreshData();
        }
        catch (err) {
            ErrorHandler.handle('on save tariff', err);
        }
    }

    const onChangeRestriction = (selected: SelectValue[]) => {
        setRestrictions(selected);
    }

    const handlePolicyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEntity({ ...entity, active: e.target.checked ? 'Y' : 'N' });
    }

    const updateName = (e: React.ChangeEvent<HTMLInputElement>, language: string) => {
        const data = entity.data ? entity.data : []
        const index = data.findIndex(x => x.language === language);
        if (index < 0) {
            data.push({
                language: language,
                name: e.target.value
            });
        }
        else {
            data[index].name = e.target.value;
        }

        setEntity({ ...entity, data: data });
        console.log('entity', entity, data);
    }

    const ruData = entity.data?.find(x => x.language === Language.ru);
    const enData = entity.data?.find(x => x.language === Language.en);
    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle titleId={entity.id < 1 ? 'tariff:add-title' : 'tariff:edit-title'} />
                <DefaultBlock>
                    <form onSubmit={onSubmit}>
                        {entity.id > 0 &&
                            <InputContainer>
                                <MDBCheckbox id="active" label={t('main:field-active')} checked={entity.active === 'Y'} onChange={handlePolicyChange} />
                            </InputContainer>
                        }
                        <InputContainer>
                            <MDBInput type="text" size="lg" value={ruData?.name ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateName(e, Language.ru)} required label={t('tariff:name')} />
                        </InputContainer>
                        <InputContainer>
                            <MDBInput type="text" size="lg" value={enData?.name ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateName(e, Language.en)} required label={t('tariff:name-en')} />
                        </InputContainer>
                        <InputContainer>
                            <RestrictionSelect value={restrictions} onChange={onChangeRestriction} isMulti title="tariff:restrictions" />
                        </InputContainer>
                        <div>
                            <MDBBtn type="submit" color="primary">{t('main:btn-save')}</MDBBtn>
                        </div>
                    </form>
                </DefaultBlock>
            </ContentWrapper>
        </>
    )
}

export default TariffEdit;
