import React, { useState, FC, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../component/contentWrapper';
import PageTitle from "../../component/pageTitle";
import Header from '../../component/layout/header';
import { AdminRoutesPath } from "./routes";
import { UserRoutesPath } from "../user/routes";

const AdminPanel: FC = () => {
    const { t } = useTranslation();
    const items = useMemo(() => [
        { link: `${UserRoutesPath.Prefix}/list`, name: 'user:menu-title', icon: 'mx-auto fas fa-users fa-5x' },
        { link: `${AdminRoutesPath.Prefix}/log`, name: 'admin:log-title', icon: 'mx-auto far fa-list-alt fa-5x' }
    ], []);

    return (
        <>
            <Header />
            <ContentWrapper>
                <div className="container-fluid">
                    <PageTitle titleId="admin:menu-title" />
                    <div className="row">
                        {items.map((item) => (
                            <div key={item.link} className="col-12 col-md-6 col-xl-4 mb-2">
                                <NavLink className="default-block admin-block p-4 d-flex" to={item.link}>
                                    <div className="w-100 d-flex flex-column">
                                        <i className={item.icon}></i>
                                        <span className="mx-auto mt-3">{t(item.name)}</span>
                                    </div>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
            </ContentWrapper>
        </>
    );
}

export default AdminPanel;
