import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    message: string
}

const FormError: FC<IProps> = ({ message }) => {
    const { t } = useTranslation();
    if (message.length < 1) {
        return (<></>);
    }
    return (
        <ul className="errors text-danger text-left">
            <li>{t(message)}</li>
        </ul>
    );
}

export default FormError;
