import axios, { AxiosRequestConfig } from "axios";

class CLmsApi {
    readonly isTest = false;

    async get<T>(method: string) {
        const response = await axios.get(method);
        return response.data as T;
    }

    async post<T>(method: string, requestData: any = {}, config?: AxiosRequestConfig<any>) {
        const response = await axios.post(method, requestData, config);
        return response.data as T;
    }

    async list<T>(method: string, requestData: any = {}) {
        const response = await axios.post(method, requestData);
        return response.data as {
            items: T[],
            total: number
        };
    }
}

export const LmsApi = new CLmsApi();
