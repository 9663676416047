import { LmsApi } from "..";
import { IAsyncSelectProps } from "../../component/filter/types/filterItem/additionalParams";
import { SelectValue } from "../../component/select/SelectValue";
import { Tariff } from "./tariff";

class CTariffService {
    async list(requestData: any) {
        const data = await LmsApi.list<Tariff>('/tariff/list', requestData);
        return data;
    }

    async get(id: number) {
        const data = await LmsApi.post<Tariff>('/tariff/get', { id: id });
        return data;
    }

    async addOrUpdate(entity: Tariff, restrictions: number[]) {
        if (entity.id < 1) {
            return await LmsApi.post<number>('/tariff/add', { ...entity, restrictions: restrictions });
        }
        return await LmsApi.post<number>('/tariff/edit', { ...entity, restrictions: restrictions });
    }

    async delete(id: number[]) {
        const data = await LmsApi.post('/tariff/delete', { id: id });
        return data;
    }

    getName(entity: Tariff, language?: string): string {
        if (!entity.data || entity.data.length < 1) {
            return `${entity.id}`;
        }

        const langData = entity.data.find(x => x.language === language);
        if (langData) {
            return langData.name;
        }
        return entity.data[0].name;
    }

    getDetailLink(entity: Tariff) {
        return `/tariff/edit/${entity.id}`;
    }

    async selectLoadOptions(inputValue: string, properties: IAsyncSelectProps): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                "data.name": inputValue
            }
        };
        const data = await TariffService.list(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item.id}` ?? '',
                label: this.getName(item, properties?.language) ?? ''
            });
        });
        return arItems;
    }
}

export const TariffService = new CTariffService();
