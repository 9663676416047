import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { MDBInput } from 'mdb-react-ui-kit';
import LanguageSelection from '../../component/languageSelection';
import { AuthRoutesPath } from "./routes";
import { ErrorHandler } from "../../service/errorHandler";
import { UserService } from "../../lmsApi/user/service";
import { useActions } from "../../hooks/useActions";


const LoginPage: FC = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { auth } = useActions();

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            await UserService.login(email, password);
            auth();
        }
        catch (err) {
            ErrorHandler.handle('login', err);
        }
    }

    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <form className="text-center auth-form app-form" onSubmit={handleSubmit}>
                <p className="h4 mt-4 mb-lg-4">{t('auth:sign-in-title')}</p>
                <section className="row px-lg-5">
                    <div className="col-12 px-lg-5 mb-4 mt-3">
                        <MDBInput type="email" name="username" id="current-email" value={email} label={t('auth:field-email')} onChange={handleEmailChange} required="required" size="lg" />
                    </div>
                    <div className="col-12 px-lg-5 mb-4">
                        <MDBInput type="password" name="current-password" id="current-password" value={password} label={t('auth:field-password')} onChange={handlePasswordChange} required="required" size="lg" minLength="10" />
                    </div>
                </section>
                <section className="row">
                    <div className="col-12 mb-4">
                        <button className="btn btn-success btn-rounded font-weight-bold px-5" type="submit"><span className="px-5">{t('auth:sign-in')}</span></button>
                    </div>
                </section>
                <section className="row px-4 auth-nav">
                    <div className="col-12 col-md-5 mb-2">
                        <Link className="link-hover" to={`${AuthRoutesPath.Prefix}/register`}>{t('auth:sign-up')}</Link>
                    </div>
                    <div className="col-12 col-md-5 mb-2">
                        <Link className="link-hover" to={`${AuthRoutesPath.Prefix}/restore`}>{t('auth:forgot-password')}</Link>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                        <LanguageSelection showText />
                    </div>
                </section>
            </form>
        </div>
    );
}

export default LoginPage;
