import { FC } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import ClientRoutes, { ClientRoutesPath } from '../../page/client/routes';
import DictionaryRoutes, { DictionaryRoutesPath } from '../../page/dictionary/routes';
import AdminRoutes, { AdminRoutesPath } from '../../page/admin/routes';
import ActionRoutes, { ActionRoutesPath } from '../../page/action/routes';
import ApplicationRoutes, { ApplicationRoutesPath } from '../../page/application/routes';
import AuthRoutes, { AuthRoutesPath } from '../../page/auth/routes';
import HomeRoutes, { HomeRoutesPath } from '../../page/home/routes';
import ProfileRoutes, { ProfileRoutesPath } from '../../page/profile/routes';
import RestrictionRoutes, { RestrictionRoutesPath } from '../../page/restriction/routes';
import TariffRoutes, { TariffRoutesPath } from '../../page/tariff/routes';
import LicenceRoutes, { LicenceRoutesPath } from '../../page/licence/routes';
import UserRoutes, { UserRoutesPath } from '../../page/user/routes';
import NotificationRoutes, { NotificationRoutesPath } from '../../page/notification/routes';
import { useTypedSelector } from "../../hooks/useTypedSelector";

const Layout: FC = () => {
    const { user } = useTypedSelector(store => store.auth);

    if (!user) {
        return (
            <Switch>
                <Route path={AuthRoutesPath.Prefix} component={AuthRoutes} />
                <Route path="/">
                    <Redirect to={`${AuthRoutesPath.Prefix}/login`} />
                </Route>
            </Switch>
        );
    }
    return (
        <Switch>
            <Route path={AuthRoutesPath.Prefix} component={AuthRoutes} />
            <Route path={AdminRoutesPath.Prefix} component={AdminRoutes} />
            <Route path={ActionRoutesPath.Prefix} component={ActionRoutes} />
            <Route path={ApplicationRoutesPath.Prefix} component={ApplicationRoutes} />
            <Route path={ClientRoutesPath.Prefix} component={ClientRoutes} />
            <Route path={DictionaryRoutesPath.Prefix} component={DictionaryRoutes} />
            <Route path={ProfileRoutesPath.Prefix} component={ProfileRoutes} />
            <Route path={RestrictionRoutesPath.Prefix} component={RestrictionRoutes} />
            <Route path={TariffRoutesPath.Prefix} component={TariffRoutes} />
            <Route path={LicenceRoutesPath.Prefix} component={LicenceRoutes} />
            <Route path={UserRoutesPath.Prefix} component={UserRoutes} />
            <Route path={UserRoutesPath.Prefix} component={UserRoutes} />
            <Route path={NotificationRoutesPath.Prefix} component={NotificationRoutes} />
            <Route path={HomeRoutesPath.Prefix} component={HomeRoutes} />
        </Switch>
    );
}

export default Layout;
