import { Link, useLocation } from 'react-router-dom';
import PageTitle from '../../component/pageTitle';
import Header from '../../component/layout/header';
import ContentWrapper from '../../component/contentWrapper';


export default function NotFound() {
    const location = useLocation();
    //todo locales
    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle text={'Страница не найдена'} />
                <h4>Возможные причины:</h4>
                <ul>
                    <li>ошибка при наборе адреса страницы ({location.pathname});</li>
                    <li>битая ссылка (неработающая, неправильная);</li>
                    <li>запрашиваемой страницы никогда не было в системе или она была удалена/перемещена.</li>
                </ul>
                <h4>Мы приносим извинения за доставленные неудобства и предлагаем следующие варианты:</h4>
                <ul>
                    <li>вернуться назад при помощи кнопки браузера «back»;</li>
                    <li>проверить правильность написания адреса страницы ({location.pathname});</li>
                    <li>перейти на <Link to="/">главную</Link> страницу.</li>
                </ul>
            </ContentWrapper>
        </>
    );
}