import PageTitle from '../../component/pageTitle';
import Header from '../../component/layout/header';
import ContentWrapper from '../../component/contentWrapper';

export default function AccessDenied() {
    return (
        <>
            <Header />
            <ContentWrapper>
                <PageTitle text={'accessDenied page'} />
                <div>accessDenied page</div>
            </ContentWrapper>
        </>
    );
}