import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import NotificationList from '../list';

export default function NotificationRoutes() {
    return (
        <Switch>
            <Route path={`${NotificationRoutesPath.Prefix}/list`} component={NotificationList} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const NotificationRoutesPath = {
    Prefix: '/notification'
};
