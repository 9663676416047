import FilterItem from '..';
import FilterType from '../../filterType';
import { IAsyncSelectProps } from '../additionalParams';

class FilterItemAsyncSelect extends FilterItem {
    /**
     * Селект с поиском
     * @param name поле сущности для фильтрации
     * @param title идентификатор названия
     */
    constructor(name: string, title: string, additionalParams: IAsyncSelectProps) {
        super(FilterType.AsyncSelect, name, title, additionalParams);
    }
}

export default FilterItemAsyncSelect;
