import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../notFound';
import Home from '../home';
import AccessDenied from '../accessDenied';

export default class HomeRoutes extends Component {
    render() {
        return (
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/error/access-denied" exact component={AccessDenied} />
                <Route path="*" component={NotFound} />
            </Switch>
        );
    }
}

export const HomeRoutesPath = {
    Prefix: '/'
};
