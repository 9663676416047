import ContentWrapper from '../../component/contentWrapper';
import Header from '../../component/layout/header';

export default function Home() {
    return (
        <>
            <Header />
            <ContentWrapper>
                <h1>h1. heading Heading</h1>
                <h2>h2. heading Heading</h2>
                <h3>h3. heading Heading</h3>
                <h4>h4. heading Heading</h4>
                <h5>h5. heading Heading</h5>
                <h6>h6. heading Heading</h6>
            </ContentWrapper>
        </>
    );
}
