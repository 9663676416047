export enum LogType {
    userRegister = 'user-register',
    userEdit = 'user-edit',
    userDelete = 'user-delete',
    applicationAdd = 'application-add',
    applicationEdit = 'application-edit',
    applicationDelete = 'application-delete',
    clientAdd = 'client-add',
    clientEdit = 'client-edit',
    clientDelete = 'client-delete',
    restrictionAdd = 'restriction-add',
    restrictionEdit = 'restriction-edit',
    restrictionDelete = 'restriction-delete',
    tariffAdd = 'tariff-add',
    tariffEdit = 'tariff-edit',
    tariffDelete = 'tariff-delete',
    licenceAdd = 'licence-add',
    licenceEdit = 'licence-edit',
    licenceDelete = 'licence-delete',
    actionAdd = 'action-add',
    actionEdit = 'action-edit',
    actionDelete = 'action-delete',
}
